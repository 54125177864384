const languageNames = {
  af: "Afrikaans",
  sq: "Albanian",
  am: "Amharic",
  ar: "Arabic",
  an: "Aragonese",
  hy: "Armenian",
  hyw: "Armenian (Western)",
  as: "Assamese",
  az: "Azerbaijani",
  ba: "Bashkir",
  eu: "Basque",
  be: "Belarusian",
  bn: "Bengali",
  bpy: "Bishnupriya Manipuri",
  bs: "Bosnian",
  bg: "Bulgarian",
  my: "Burmese",
  ca: "Catalan",
  yue: "Cantonese",
  hak: "Hakka",
  haw: "Hawaiian",
  cmn: "Mandarin",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  en: "English",
  en_us: "English (US)",
  en_gb: "English (UK)",
  en_029: "English (Caribbean)",
  en_gb_x_gbclan: "English (Lancastrian)",
  en_gb_x_rp: "English (Received Pronunciation)",
  en_gb_scotland: "English (Scottish)",
  en_gb_x_gbcwmd: "English (West Midlands)",
  eo: "Esperanto",
  et: "Estonian",
  fa: "Persian",
  fa_latn: "Persian (Latin)",
  fi: "Finnish",
  fr: "French",
  fr_be: "French (Belgium)",
  fr_ch: "French (Switzerland)",
  ga: "Irish",
  gd: "Scottish Gaelic",
  ka: "Georgian",
  de: "German",
  grc: "Ancient Greek",
  el: "Modern Greek",
  kl: "Greenlandic",
  gn: "Guarani",
  gu: "Gujarati",
  ht: "Haitian Creole",
  he: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  ia: "Interlingua",
  io: "Ido",
  it: "Italian",
  ja: "Japanese",
  kn: "Kannada",
  kok: "Konkani",
  ko: "Korean",
  ku: "Kurdish",
  kk: "Kazakh",
  ky: "Kyrgyz",
  la: "Latin",
  lb: "Luxembourgish",
  ltg: "Latgalian",
  lv: "Latvian",
  lfn: "Lingua Franca Nova",
  lt: "Lithuanian",
  jbo: "Lojban",
  mi: "Māori",
  mk: "Macedonian",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  mr: "Marathi",
  nci: "Classical Nahuatl",
  ne: "Nepali",
  nb: "Norwegian Bokmål",
  nog: "Nogai",
  or: "Odia",
  om: "Oromo",
  pap: "Papiamento",
  py: "Pyash",
  pl: "Polish",
  pt: "Portuguese",
  pt_br: "Portuguese (Brazil)",
  qdb: "Lang Belta",
  qu: "Quechua",
  quc: "K'iche'",
  qya: "Quenya",
  pa: "Punjabi",
  piqd: "Klingon",
  ro: "Romanian",
  ru: "Russian",
  ru_lv: "Russian (Latvia)",
  uk: "Ukrainian",
  sjn: "Sindarin",
  sr: "Serbian",
  tn: "Setswana",
  sd: "Sindhi",
  shn: "Shan",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  smj: "Lule Sami",
  es: "Spanish",
  es_419: "Spanish (Latin America)",
  sw: "Swahili",
  sv: "Swedish",
  ta: "Tamil",
  th: "Thai",
  tk: "Turkmen",
  tt: "Tatar",
  te: "Telugu",
  tr: "Turkish",
  ug: "Uyghur",
  ur: "Urdu",
  uz: "Uzbek",
  vi_vn_x_central: "Vietnamese (Central)",
  vi: "Vietnamese",
  vi_vn_x_south: "Vietnamese (Southern)",
  cy: "Welsh",
  zh: "Chinese",
};

export default languageNames;
import { format, max, set } from "date-fns";
import React, { useEffect, useState } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import PracticeSessionStatsService from "@/services/PracticeSessionStatService";
import { ThemeProvider } from "@/components/ThemeProvider";
import { Bars } from "react-loader-spinner";
import InnerContainerLoader from "@/components/InnerContainerLoader";
import { useTheme } from "next-themes";

import "@/components/TopBarStyles.css";

// Assuming Tremor components are imported correctly
// import { Chart } from 'tremor-react';

import "react-calendar-heatmap/dist/styles.css";
import "./stats.css";
import "react-tooltip/dist/react-tooltip.css";
import { data } from "autoprefixer";

const Stats = () => {
  const { theme } = useTheme();

  const [resolution, setResolution] = useState("7_days");
  const [dailyStats, setDailyStats] = useState(null);

  const [heatmapData, setHeatmapData] = useState([]);
  const [totalWordData, setTotalWordData] = useState([]);
  const [totalDurationData, setTotalDurationData] = useState([]);

  const [maxTotalWords, setMaxTotalWords] = useState(0);
  const [maxTotalDuration, setMaxTotalDuration] = useState(0);

  const [dataFetched, setDataFetched] = useState(false);

  const handleResolutionChange = (selectedResolution) => {
    setResolution(selectedResolution);
  };

  const getHeatmapColor = (count) => {
    if (!count) {
      return "color-empty";
    }
    const maxCount = maxTotalWords; // Assuming maxTotalWords is updated elsewhere as shown
    const interval = maxCount / 4; // Divide range into 4 intervals

    if (count <= interval) {
      return "color-scale-0";
    } else if (count <= interval * 2) {
      return "color-scale-1";
    } else if (count <= interval * 3) {
      return "color-scale-2";
    } else {
      return "color-scale-3";
    }
  };

  useEffect(() => {
    const root = document.documentElement;

    if (theme === "light") {
      root.style.setProperty("--heatmap-color-scale-0", "hsl(0deg, 0%, 90%)");
      root.style.setProperty("--heatmap-color-scale-1", "#d6e685");
      root.style.setProperty("--heatmap-color-scale-2", "#8cc665");
      root.style.setProperty("--heatmap-color-scale-3", "#44a340");
      root.style.setProperty("--heatmap-color-scale-4", "#1e6823");
      root.style.setProperty("--heatmap-color-empty", "hsl(0deg, 0%, 90%)");
    } else {
      root.style.setProperty(
        "--heatmap-color-scale-0",
        "hsl(210deg, 10.5263157895%, 19.9019607843%)"
      );
      root.style.setProperty("--heatmap-color-scale-1", "#d6e685");
      root.style.setProperty("--heatmap-color-scale-2", "#8cc665");
      root.style.setProperty("--heatmap-color-scale-3", "#44a340");
      root.style.setProperty("--heatmap-color-scale-4", "#1e6823");
      root.style.setProperty(
        "--heatmap-color-empty",
        "hsl(210deg, 10.5263157895%, 19.9019607843%)"
      );
    }
  }, [theme]);

  useEffect(() => {
    const fetchDailyStats = async () => {
      const today = new Date().toISOString().split("T")[0];
      const stats =
        await PracticeSessionStatsService.getDailyPracticeSessionStats(today);
      setDailyStats(stats);
    };

    const fetchPeriodicStatsData = async () => {
      const endDate = new Date();
      const startDate = new Date();

      // Adjust the start date based on the selected resolution
      if (resolution === "7_days") {
        startDate.setDate(startDate.getDate() - 7);
      } else if (resolution === "30_days") {
        startDate.setDate(startDate.getDate() - 30);
      } else if (resolution === "1_year") {
        startDate.setFullYear(startDate.getFullYear() - 1);
      }

      // Fetch data for the selected date range
      const stats =
        await PracticeSessionStatsService.getPeriodicPracticeSessionStats(
          startDate.toISOString().split("T")[0],
          endDate.toISOString().split("T")[0],
          "daily"
        );
      setDataFetched(true);

      // Format the data to match the structure expected by the CalendarHeatmap component
      const totalWordData = stats.map((stat) => ({
        date: format(new Date(stat.date), "yyyy-MM-dd"), // Format date to 'yyyy-MM-dd'
        count: stat.total_words, // Use total_words as the count
      }));

      const totalDurationData = stats.map((stat) => ({
        date: format(new Date(stat.date), "yyyy-MM-dd"), // Format date to 'yyyy-MM-dd'
        count: parseInt(stat.total_duration, 10),
      }));

      setHeatmapData(totalWordData);
      setTotalDurationData(totalDurationData);
      setTotalWordData(totalWordData);

      let maxCount = Math.max(...totalWordData.map((data) => data.count));
      setMaxTotalWords(maxCount);
      maxCount = Math.max(...totalDurationData.map((data) => data.count));
      setMaxTotalDuration(maxCount);
    };

    fetchDailyStats();
    fetchPeriodicStatsData();
  }, [resolution]);

  if (!dailyStats) {
    return (
      <ThemeProvider attribute="class" defaultTheme="dark">
        <InnerContainerLoader offset={12} />
      </ThemeProvider>
    );
  }

  return (
    // Adjusting margins for mobile and desktop views
    <div className="h-[90vh] overflow-scroll scrollbar-hide">
      {/* Header */}

      <div className="w-full fixed md:absolute fixed top-0 left-0 right-0 z-10 flex items-center h-16 md:pl-0 md:mt-12 mt-0  pl-14 pr-4 sm:shadow-md md:shadow-none dark:bg-zinc-900 custom-dark-bg-reset">
        <h1 className="text-xl md:ml-64 ml-0">Stats</h1>
      </div>

      <div className="md:p-0 p-6 md:mt-0 mt-16">
        {/* Heatmap Section */}
        <div className="md:p-4 mt-2 mb-4 w-[95%] sm:w-[80%] md:w-[70%] max-w-full mx-auto">
          <CalendarHeatmap
            startDate={new Date(new Date().getFullYear(), 0, 1)}
            endDate={new Date(new Date().getFullYear(), 11, 31)}
            values={heatmapData}
            classForValue={(value) => {
              return getHeatmapColor(value ? value.count : null);
            }}
            tooltipDataAttrs={(value) => {
              return {
                "data-tooltip-id": "heatmap-tooltip",
                "data-tooltip-content": value.date
                  ? `${format(new Date(value.date), "MMM dd")} - ${value.count} words`
                  : "No data for this day",
              };
            }}
            showWeekdayLabels={false}
          />
          <ReactTooltip id="heatmap-tooltip" />
        </div>

        {/* KPI Cards Row */}
        <div className="text-[24px]">Today</div>
        <div className="flex flex-wrap justify-between mb-8">
          <div className="p-4 flex-1 mx-2 mb-4 md:mb-0">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Time practiced (s)
                </CardTitle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="h-4 w-4 text-muted-foreground"
                >
                  <circle cx="12" cy="12" r="10" />
                  <polyline points="12 6 12 12 16 14" />
                </svg>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {parseInt(dailyStats.total_duration, 10)}
                </div>
                {/* <p className="text-xs text-muted-foreground">
                +20.1% from last month
              </p> */}
              </CardContent>
            </Card>
          </div>

          <div className="p-4 flex-1 mx-2 mb-4 md:mb-0">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Words spoken
                </CardTitle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="h-4 w-4 text-muted-foreground"
                >
                  <path d="M12 1a3 3 0 0 1 3 3v8a3 3 0 0 1-6 0V4a3 3 0 0 1 3-3z" />
                  <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
                  <line x1="12" y1="19" x2="12" y2="23" />
                  <line x1="8" y1="23" x2="16" y2="23" />
                </svg>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {Number(
                    dailyStats.total_words
                      ? parseFloat(dailyStats.total_words).toFixed(0)
                      : 0
                  )}
                </div>

                {/* <p className="text-xs text-muted-foreground">
                +20.1% from last month
              </p> */}
              </CardContent>
            </Card>
          </div>
          <div className="p-4 flex-1 mx-2">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Words per minute
                </CardTitle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="h-4 w-4 text-muted-foreground"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M12 6v6l4 2" />
                  <path d="M4.93 4.93l2 2" />
                  <path d="M19.07 4.93l-2 2" />
                </svg>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {Number(
                    dailyStats.avg_wpm
                      ? parseFloat(dailyStats.avg_wpm).toFixed(0)
                      : 0
                  )}
                </div>
                {/* <p className="text-xs text-muted-foreground">
                +20.1% from last month
              </p> */}
              </CardContent>
            </Card>
          </div>
        </div>

        {/* 2x2 Graph Grid */}
        <div className="text-[24px]">Last 7 days</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Graph 1 */}
          <div className="p-4 mb-4 md:mb-0">
            {/* <Chart> Graph 1 Details Here </Chart> */}
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Words spoken
                </CardTitle>
              </CardHeader>
              <CardContent>
                {dataFetched ? (
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={totalWordData}>
                      <XAxis
                        dataKey="date"
                        stroke="#888888"
                        fontSize={12}
                        tickFormatter={(value) => {
                          const date = new Date(value);
                          const formattedDate = date.toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                            }
                          );
                          return formattedDate;
                        }}
                        axisLine={false}
                      />
                      <YAxis
                        stroke="#888888"
                        fontSize={12}
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) => `${value}`}
                        domain={[0, maxTotalWords]}
                      />
                      <Bar
                        dataKey="count"
                        fill="#16a34a"
                        radius={[4, 4, 0, 0]}
                        className="fill-primary"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="h-[350px]">
                    <InnerContainerLoader offset={36} />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          {/* Graph 2 */}
          <div className="p-4 mb-4 md:mb-0">
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-md font-medium">
                  Time practiced (s)
                </CardTitle>
              </CardHeader>
              <CardContent>
                {dataFetched ? (
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={totalDurationData}>
                      <XAxis
                        dataKey="date"
                        stroke="#888888"
                        fontSize={12}
                        tickFormatter={(value) => {
                          const date = new Date(value);
                          const formattedDate = date.toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                            }
                          );
                          return formattedDate;
                        }}
                        axisLine={false}
                      />
                      <YAxis
                        stroke="#888888"
                        fontSize={12}
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) => `${value}`}
                        domain={[0, maxTotalDuration]}
                      />
                      <Bar
                        dataKey="count"
                        fill="#16a34a"
                        radius={[4, 4, 0, 0]}
                        className="fill-primary"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="h-[350px]">
                    <InnerContainerLoader offset={36} />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          {/* <div className="bg-pink-200 p-4 mb-4 md:mb-0">
          Graph 3 Placeholder
        </div>
        <div className="bg-teal-200 p-4">
          Graph 4 Placeholder
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Stats;

import { requestWithAuth } from "@/utils/authUtils";

const PracticeSessionStatsService = {
  async getUserPracticeSessionStats() {
    try {
      const url = `/practice-session-stats`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching practice session stats:", error);
      throw error;
    }
  },

  async createPracticeSessionStat(
    content_id,
    collection_id,
    language,
    words_per_minute,
    accuracy,
    total_words,
    words,
    transcribed_words,
    wrong_words,
    duration
  ) {
    try {
      const url = `/practice-session-stats`;
      const method = "POST";
      const data = {
        content_id,
        collection_id,
        language,
        words_per_minute,
        accuracy,
        total_words,
        words,
        transcribed_words,
        wrong_words,
        duration,
      };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in creating practice session stat:", error);
      throw error;
    }
  },

  async deletePracticeSessionStat(statId) {
    try {
      const url = `/practice-session-stats/${statId}`;
      const method = "DELETE";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in deleting practice session stat:", error);
      throw error;
    }
  },
  async getDailyPracticeSessionStats(date) {
    try {
      const url = `/practice-session-stats/daily?date=${date}`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching daily practice session stats:", error);
      throw error;
    }
  },

  async getPeriodicPracticeSessionStats(startDate, endDate, resolution = "daily") {
    try {
      const url = `/practice-session-stats/periodic?start_date=${startDate}&end_date=${endDate}&resolution=${resolution}`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching periodic practice session stats:", error);
      throw error;
    }
  },
  async getAccuracyTrendForWord(word, language) {
    try {
      const url = `/practice-session-stats/accuracy-trend?word=${encodeURIComponent(word)}&language=${encodeURIComponent(language)}`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching accuracy trend for word:", error);
      throw error;
    }
  },
}

export default PracticeSessionStatsService;

import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import CollectionService from "@/services/CollectionService";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  TrashIcon,
  ReloadIcon,
  Pencil1Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  VideoIcon,
} from "@radix-ui/react-icons";
import { Youtube } from "lucide-react";

import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import languageMap from "@/constants/languageMap";
import languageNames from "@/constants/languageNames";

import "/node_modules/flag-icons/css/flag-icons.min.css";

import "@/components/TopBarStyles.css";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import useStore from "@/store";
import { set } from "date-fns";

import posthog from "posthog-js";

const CollectionEditor = ({ collection }) => {
  const navigate = useNavigate();

  const { user, setCurrentCollectionId } = useStore().user;
  const { setCurrentCollectionById } = useStore().collections;

  const [language, setLanguage] = useState(collection?.language || "en-us");

  const { collectionId } = useParams();

  const [title, setTitle] = useState(collection?.title || "Untitled");

  const [youtubeUrl, setYoutubeUrl] = useState("");

  const scrollContainerRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false);

  // const [contents, setContents] = useState(
  //   collection ? collection.map((item) => item.text) : [""]
  // );
  const [contents, setContents] = useState(
    collection
      ? collection.contents.map((item) => ({ id: item.id, text: item.text }))
      : []
  );

  const [originalContents, setOriginalContents] = useState([]);

  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hovered textarea

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const titleInputRef = useRef(null);

  const handlePractice = async () => {
    try {
      await setCurrentCollectionId(collection.id);
      navigate("/");
    } catch (error) {
      console.error("Failed to set current collection ID:", error);
    }
  };

  function mapLanguageToCountry(language) {
    // Default mapping for 'en' to 'gb'
    if (language === "en") {
      return "gb";
    }

    // Specific mappings for variations of English
    switch (language) {
      case "ja":
        return "jp";
      case "hi":
        return "in";
      case "en-us":
        return "us";
      case "en-gb":
      case "en-gb":
        return "gb";
      case "zh":
        return "cn";
      case "ar":
        return "sa";
      case "ko":
        return "kr";
      default:
        // If no specific mapping is found, return the first part of the language code
        // This assumes language co des are in the format of 'language-country'
        return language.split("-")[0];
    }
  }

  useEffect(() => {
    // Automatically focus the title input when starting to edit
    if (isEditingTitle) {
      titleInputRef.current?.focus();
    }
  }, [isEditingTitle]);

  const onSave = async (collectionDetails) => {
    console.log("Saving new collection with details:", collectionDetails);
    // Here, implement your logic to save the collection

    if (collectionId) {
      let collection = await CollectionService.updateCollection(
        collectionId,
        collectionDetails.language,
        collectionDetails.title,
        originalContents,
        collectionDetails.contents,
        collectionDetails.type,
        collectionDetails.youtubeUrl
      );
      setOriginalContents(collectionDetails.contents);
      toast("Collection updated", {
        description: "The collection has been successfully updated!",
        action: {
          label: "Close",
          onClick: () => console.log("Undo action clicked"),
        },
      });
      posthog.capture("collection_updated");
    } else {
      // Logic for creating a new collection
      try {
        const newCollection =
          await CollectionService.createCollectionWithContents(
            collectionDetails.title,
            collectionDetails.language,
            collectionDetails.contents,
            collectionDetails.type,
            collectionDetails.youtubeUrl
          );

        // Assuming `newCollection` contains the ID of the created collection
        // And `setCurrentCollectionId` is a method in your user store that updates the current collection ID
        if (newCollection && newCollection.id) {
          // Update the current collection ID in the user's profile
          console.log("setting the current collection id", newCollection.id);
          setCurrentCollectionId(newCollection.id);
          setCurrentCollectionById(newCollection.id);
        }

        toast("Success", {
          description: "The collection was created successfully!",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        });

        posthog.capture("collection_created");
        navigate("/");
      } catch (error) {
        console.error("Failed to create collection:", error);
        toast("Error", {
          description: `Failed to create collection: ${error.message || "An error occurred"}`,
          action: {
            label: "Close",
            onClick: () => console.log("Error Close"),
          },
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const collection =
          await CollectionService.getCollectionById(collectionId);
        console.log("collection fetched:", collection);
        setTitle(collection.title);
        let contents = collection.contents
          ? collection.contents.map((item) => ({
              id: item.id,
              text: item.text,
            }))
          : [{ id: null, text: "" }];

        setOriginalContents(contents);
        setContents(contents);
      } catch (error) {
        console.error("Failed to fetch collection:", error);
        // Handle error (e.g., show error message)
      }
    };

    if (collectionId) {
      fetchCollection();
    }
  }, [collectionId]);

  // Handle clicking outside the title input to stop editing
  const handleClickOutside = (event) => {
    if (
      titleInputRef.current &&
      !titleInputRef.current.contains(event.target)
    ) {
      setIsEditingTitle(false);
    }
  };

  useEffect(() => {
    // Attach the event listener to handle clicks outside the title input
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle change in textareas
  const handleChange = (index, value) => {
    const newContents = [...contents];
    newContents[index] = { ...newContents[index], text: value };
    setContents(newContents);
  };

  // Insert a new textarea with empty content at a specified position
  const handleAddAbove = (index) => {
    const newContents = [
      ...contents.slice(0, index),
      { id: null, text: "" },
      ...contents.slice(index),
    ];
    setContents(newContents);
  };

  const handleAddBelow = (index) => {
    const newContents = [
      ...contents.slice(0, index + 1),
      { id: null, text: "" }, // Add a new content item below the current index
      ...contents.slice(index + 1),
    ];
    setContents(newContents);

    // Check if the addition is happening at the last item
    if (index === contents.length - 1) {
      // Wait for the state to update and the component to re-render
      setTimeout(scrollToBottom, 0);
    }
  };

  // Add new textarea at the top
  const handleAddAtTop = () => {
    const newContents = [{ id: null, text: "" }, ...contents]; // Add a new content item at the beginning
    setContents(newContents);
  };

  // Delete a specific textarea/content
  const handleDelete = (index) => {
    const newContents = contents.filter((_, i) => i !== index);
    setContents(newContents);
  };

  // Save the collection with its title
  const handleSave = async () => {
    setIsSaving(true); // Indicate that saving has started
    try {
      const collectionDetails = {
        title,
        language,
        youtubeUrl,
        type: youtubeUrl.trim() !== "" ? "youtube" : "text",
        contents: contents
          .filter((content) => content.text.trim() !== "")
          .map((content) => ({ id: content.id, text: content.text })),
      };

      await onSave(collectionDetails);

      // If saving is successful and you navigate away, consider where and how to setIsSaving(false)
      // navigate("/collections");
    } catch (error) {
      console.error("Failed to save collection:", error);
      // Optionally, show an error message to the user
    } finally {
      setIsSaving(false); // Indicate that saving has finished
    }
  };

  const scrollToBottom = () => {
    const scrollHeight = scrollContainerRef.current.scrollHeight;
    const height = scrollContainerRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    scrollContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  console.log("contents:", contents);

  return (
    <div>
      <div className="md:relative md:w-full w-[100svw] fixed flex items-center justify-between mt-auto h-16 md:pl-0 pl-14 pr-4 sm:shadow-md md:shadow-none dark:bg-zinc-900 custom-dark-bg-reset">
        <div className="flex items-center">
          <div className="relative">
            <button
              className="absolute top-0 left-0 -mt-16 -ml-6 text-gray-300 hover:text-gray-200 focus:outline-none"
              onClick={() => navigate("/collections")}
            >
              <ChevronLeftIcon className="w-6 h-6 opacity-50" />
            </button>
            {/* <span
              className={`fi fi-${mapLanguageToCountry(language)} mr-2`}
            ></span> */}
          </div>
          <Select className="w-full" defaultValue={language} onValueChange={setLanguage}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en-us">English - US</SelectItem>
              <SelectItem value="en-gb">English - UK</SelectItem>
              <SelectItem value="en-gb-x-rp">
                English - Received Pronunciation
              </SelectItem>

              <SelectItem value="fr">French</SelectItem>
              <SelectItem value="fr-be">French - Belgium</SelectItem>
              <SelectItem value="fr-ch">French - Switzerland</SelectItem>

              <SelectItem value="de">German</SelectItem>
              {/* <SelectItem value="ja">Japanese</SelectItem> */}
              <SelectItem value="hi">Hindi</SelectItem>

              <SelectItem value="es">Spanish</SelectItem>
              <SelectItem value="es-419">Spanish - Latin American</SelectItem>

              {/* <SelectItem value="zh">Chinese</SelectItem> */}
              <SelectItem value="it">Italian</SelectItem>
              <SelectItem value="ru">Russian</SelectItem>

              <SelectItem value="pt">Portuguese</SelectItem>
              <SelectItem value="pt-br">Portuguese - Brazil</SelectItem>

              <SelectItem value="ar">Arabic</SelectItem>
              <SelectItem value="ko">Korean</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex">
          <Button
            className="dark:bg-zinc-900 dark:hover:bg-zinc-950 dark:text-white mr-2"
            onClick={handlePractice}
          >
            Practice
          </Button>
          <Button
            className="bg-green-500 hover:bg-green-600 dark:bg-green-500 dark:hover:bg-green-400"
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>

      <div
        className="flex w-full pr-5 items-center md:ml-0 ml-6 md-mt-0 md:pt-0 pt-24"
        onClick={() => setIsEditingTitle(true)}
      >
        {collection && collection.content_type === "youtube" && (
          <a
            href={collection.video_url}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-2"
          >
            {/* <Youtube strokeWidth={1.5} size={22} /> */}
            <VideoIcon stroke-width="1" className="w-5 h-5 cursor-pointer" />
          </a>
        )}
        {isEditingTitle ? (
          <Input
            ref={titleInputRef}
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => setIsEditingTitle(false)}
            className="text-xl w-[95%] border-0 p-0 m-0 leading-normal focus:outline-none"
            placeholder="Collection Title"
          />
        ) : (
          <div className="flex-grow flex items-center">
            <h2 className="text-xl cursor-pointer pt-1 pb-1">{title}</h2>
            <Pencil1Icon
              className="cursor-pointer ml-3 opacity-50"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditingTitle(true);
              }}
            />
          </div>
        )}
      </div>

      <div className="space-x-2 md:pl-4 pl-12 mt-12 pb-2">
        <Button onClick={handleAddAtTop}>+ Add</Button>
      </div>

      {/* Scrollable container for contents */}
      <div
        className="w-full overflow-auto flex-grow pt-1"
        style={{ height: "calc(100vh - 300px)" }}
        ref={scrollContainerRef}
      >
        {contents.map((content, index) => (
          <div key={index} className="ml-1 mt-6 mb-6 pr-12 md:pl-4 pl-12">
            <div
              className="group relative"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <TextareaAutosize
                className="w-full p-4 order border dark:border-gray-700 border-gray-200 dark:bg-dark-grey rounded text-lg dark:text-gray-200"
                value={content.text}
                minRows={1}
                onChange={(e) => handleChange(index, e.target.value)}
              />
              {hoveredIndex === index && (
                <>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => handleAddAbove(index)}
                    className="w-7 h-7 z-100 absolute top-0 left-1/2 transform -translate-y-full -translate-x-1/2 dark:bg-dark-grey hover:bg-green-700 text-gray-400 py-1 px-2"
                  >
                    +
                  </Button>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => handleAddBelow(index)}
                    className="w-7 h-7 z-100 absolute bottom-2 left-1/2 transform translate-y-full -translate-x-1/2 dark:bg-dark-grey hover:bg-green-700 text-gray-400 py-1 px-2"
                  >
                    +
                  </Button>
                  <Button
                    variant="destructive"
                    size="icon"
                    onClick={() => handleDelete(index)}
                    className="w-7 h-7 z-100 absolute top-0 right-0 transform translate-x-full bg-red-500 hover:bg-red-700 dark:text-gray-400 text-gray-200 rounded"
                  >
                    <TrashIcon />
                  </Button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollectionEditor;

import React, { useState, useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import PracticeSessionStatsService from "@/services/PracticeSessionStatService";
import { Bars } from "react-loader-spinner";

const TrendLineChart = ({ word, language }) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [accuracyData, setAccuracyData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
      }
    };

    const fetchAccuracyData = async () => {
      try {
        setIsLoading(true);
        const response =
          await PracticeSessionStatsService.getAccuracyTrendForWord(word, language);
        console.log("response", response);
        setAccuracyData(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching accuracy trend data:", error);
        setIsLoading(false);
      }
    };

    fetchAccuracyData();
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [word]);

  return (
    <div ref={chartContainerRef} style={{ width: "100%", height: "200px" }}>
      <h2 style={{ textAlign: "center" }}>Accuracy Over Time</h2>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Bars color="green" height={20} width={20} />
        </div>
      ) : accuracyData && chartWidth > 0 && accuracyData.length > 0 ? (
        <LineChart
          width={chartWidth}
          height={200}
          data={accuracyData}
          animationDuration={10}
          margin={{ top: 20, right: 15, bottom: 0, left: -25 }}
        >
          <CartesianGrid strokeDasharray="0" stroke="transparent" />
          <XAxis dataKey="created_at" tick={false} />
          <YAxis allowDataOverflowY={true} domain={[0, 100]} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="accuracy"
            stroke="green"
            isAnimationActive={false}
          />
        </LineChart>
      ) : (
        <div style={{ textAlign: "center" }}>No data available</div>
      )}
    </div>
  );
};

export default TrendLineChart;

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { BarChart2 } from "lucide-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import WordAccuracyGraph from "@/components/LearningSession/TextWall/WordAccuracyGraph.jsx";

import PhonemeTableBody from "@/components/LearningSession/TextWall/PhonemeTableBody";
import TooltipWrapper from "@/components/TooltipWrapper";
import { Input } from "@/components/ui/input";
import { usePhonemeRecognition } from "@/speechToText/usePhonemeRecognition";
import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStore from "@/store";

import {
  faMicrophone,
  faStop,
  faVolumeUp,
  faVolumeDown,
} from "@fortawesome/free-solid-svg-icons";
import languageMap from "@/constants/languageMap";

const speakWord = (word, language, rate = 1) => {
  var msg = new SpeechSynthesisUtterance(word);
  const convertedLanguageKey = language.replace(/-/g, "_");
  msg.lang = languageMap[convertedLanguageKey] || "en-US";
  msg.rate = rate;

  window.speechSynthesis.speak(msg);
};

const playSound = (phonemeName) => {
  const url = `https://www.internationalphoneticalphabet.org/ipa-chart-audio/mp3/${phonemeName}.mp3`;
  const audio = new Audio(url);
  audio.play();
};

let successSound = new Audio("/correct.mp3");
export default function WordPracticeDialog({ setOpen, open, word, language }) {
  const { wordPracticeDialogOpened, setWordPracticeDialogOpened } =
    useStore().learningSessionConfig;
  const { soundEffects } = useStore().settings;

  const phonemeHook = usePhonemeRecognition();
  const [accuracy, setAccuracy] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transcribedPhonemesList, setTranscribedPhonemesList] = useState([]);
  const [directMatches, setDirectMatches] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (phonemeHook.phonemes.length > 0) {
      setAccuracy(phonemeHook.overallAccuracy);
      setTranscribedPhonemesList(phonemeHook.transcribedPhonemesList);
      setDirectMatches(phonemeHook.directMatches);
      setIsLoading(false);

      if (
        Math.floor(phonemeHook.overallAccuracy) === 100 &&
        soundEffects &&
        isMounted &&
        open &&
        !isRecording
      ) {
        successSound
          .play()
          .catch((error) => console.error("Failed to play the sound:", error));
      }
    }

    return () => {
      isMounted = false;
      if (successSound) {
        successSound.pause();
        successSound.currentTime = 0;
      }
    };
  }, [phonemeHook, open, isRecording]);

  useEffect(() => {
    setWordPracticeDialogOpened(open);
    if (!open) {
      phonemeHook.cancelPhonemeRecognition();
      setIsRecording(false);
      setIsLoading(false);
    }
  }, [open]);

  const toggleRecording = useCallback(() => {
    if (isRecording) {
      phonemeHook.stopPhonemeRecognition();
      setIsLoading(true);
    } else {
      phonemeHook.startPhonemeRecognition(word, language, false);
    }
    setIsRecording(!isRecording);
  }, [isRecording, phonemeHook, word, language]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (open) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (event.code === "Space") {
          event.preventDefault();
          toggleRecording();
        } else if (event.code === "KeyL") {
          event.preventDefault();
          speakWord(word, language);
        } else if (event.code === "KeyK") {
          event.preventDefault();
          speakWord(word, language, 0.1);
        } else if (event.code === "Escape") {
          event.preventDefault();
          setOpen(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, toggleRecording, word, language, setOpen]);

  const filteredTranscribedPhonemes =
    phonemeHook.phonemes.length > 0
      ? phonemeHook.phonemes[0].transcribed_phonemes.filter(
          (phoneme) => phoneme !== ""
        )
      : [];

  const getColor = (accuracy) => {
    if (accuracy >= 0 && accuracy < 17) {
      return "#dc2626"; // Red
    } else if (accuracy >= 17 && accuracy < 34) {
      return "#ea580c"; // OrangeRed
    } else if (accuracy >= 34 && accuracy < 51) {
      return "#d97706"; // DarkOrange
    } else if (accuracy >= 51 && accuracy < 68) {
      return "#ca8a04"; // Gold
    } else if (accuracy >= 68 && accuracy < 85) {
      return "#65a30d"; // YellowGreen
    } else {
      return "#16a34a"; // Green
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex justify-between items-center text-2xl">
            <div className="flex items-center">
              <span>{word}</span>

              {/* Volume Up Icon */}
              <FontAwesomeIcon
                icon={faVolumeUp}
                onClick={() => speakWord(word, language)}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  marginLeft: "12px",
                  opacity: 0.8,
                }}
              />

              {/* Slow Speak Icon */}
              <FontAwesomeIcon
                icon={faVolumeDown}
                onClick={() => speakWord(word, language, 0.1)}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  marginLeft: "8px",
                  opacity: 0.8,
                }}
              />
            </div>

            {/* Word Accuracy Popover on the right */}
            <div className="mr-3">
              <Popover>
                <PopoverTrigger>
                  <div className="cursor-pointer dark:bg-zinc-800 bg-gray-100 border dark:border-zinc-800 border-gray-200 rounded-md pl-1 pr-1 inline-block">
                    <BarChart2 style={{ width: "16px" }} />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <WordAccuracyGraph word={word} language={language} />
                </PopoverContent>
              </Popover>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center justify-center">
            <TooltipWrapper tooltipText={"Toggle speaking"}>
              <button
                className={`rounded-full flex items-center justify-center transition duration-300 ease-in-out w-[50px] h-[50px] relative ${
                  isRecording || isLoading
                    ? "bg-red-500 hover:bg-red-700 pulsating-halo"
                    : "bg-slate-200 hover:bg-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700"
                } ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                disabled={isLoading}
                onClick={!isLoading ? toggleRecording : undefined}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <svg
                      className="animate-spin h-8 w-8 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : isRecording ? (
                  <FontAwesomeIcon
                    icon={faStop}
                    className="icon text-white"
                    style={{ fontSize: "24px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    className="icon"
                    style={{ fontSize: "18px" }}
                  />
                )}
              </button>
            </TooltipWrapper>
          </div>
          <div className="flex">
            {/* Matches: &nbsp; */}
            {phonemeHook.phonemes[0] &&
              phonemeHook.phonemes[0].phoneme_matches.map((phoneme, index) => {
                const [phonemeLetter, status] = Object.entries(phoneme)[0];
                const color = status === "X" ? "#ff0000" : "#4fb701";
                return (
                  <div
                    key={index}
                    style={{ color, marginRight: "5px" }}
                    className="text-lg"
                  >
                    {phonemeLetter}
                  </div>
                );
              })}
          </div>
          {phonemeHook.phonemes.length > 0 && (
            <div>
              <div className="flex items-center space-x-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className="h-2.5 rounded-full"
                    style={{
                      width: `${accuracy}%`,
                      backgroundColor: getColor(accuracy),
                    }}
                  ></div>
                </div>
                <span className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
                  {accuracy.toFixed(0)}%
                </span>
              </div>
              <div className="mt-4 border dark:border-zinc-700 border-gray-100 rounded-md md:max-h-[60vh] max-h-[50vh] overflow-y-auto">
                <table className="min-w-full divide-y dark:divide-zinc-700 divide-gray-100">
                  <thead className="dark:bg-zinc-800 bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-2 text-left text-xs font-medium text-black dark:text-zinc-300 uppercase"
                      >
                        Sound
                      </th>
                      <th
                        scope="col"
                        className="w-[100%] px-2 py-2 text-left text-xs font-medium text-black dark:text-zinc-300 uppercase"
                      >
                        You Said
                      </th>
                    </tr>
                  </thead>
                  <PhonemeTableBody
                    directMatches={directMatches}
                    language={language}
                  />
                </table>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

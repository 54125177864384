import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faMicrophone,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "@/components/ui/badge";
import { usePhonemeRecognition } from "@/speechToText/usePhonemeRecognition";
import PhonemeInstructorService from "@/services/PhonemeInstructorService";
import useStore from "@/store";

import "../LearningSession.css";
const playSound = (phonemeName) => {
  const url = `https://www.internationalphoneticalphabet.org/ipa-chart-audio/mp3/${phonemeName}.mp3`;
  const audio = new Audio(url);
  audio.play();
};

function PhonemeTableBody({ directMatches, language }) {
  const { settings } = useStore().settings;

  const phonemeHook = usePhonemeRecognition();
  const [selectedPhonemeIndex, setSelectedPhonemeIndex] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [directPhonemeMatches, setDirectPhonemeMatches] =
    useState(directMatches);
  const [phonemeInstructions, setPhonemeInstructions] = useState({});
  const [instructionsLoading, setInstructionsLoading] = useState({});

  useEffect(() => {
    setDirectPhonemeMatches(directMatches);
  }, [directMatches]);

  const toggleRecording = async (phonemeIndex) => {
    if (isRecording) {
      setIsLoading(true);
      await phonemeHook.stopPhonemeRecognition();
      setIsRecording(false);
    } else {
      const selectedPhoneme = directMatches[phonemeIndex].original_phoneme;
      await phonemeHook.startPhonemeRecognition(
        selectedPhoneme,
        language,
        false,
        false
      );
      setSelectedPhonemeIndex(phonemeIndex);
      setIsRecording(true);

      // Reset the transcribed phoneme and is_correct properties
      const updatedDirectMatches = [...directMatches];
      updatedDirectMatches[phonemeIndex] = {
        ...updatedDirectMatches[phonemeIndex],
        transcribed_phoneme: "",
        is_correct: false,
      };
      setDirectPhonemeMatches(updatedDirectMatches);
    }
  };

  useEffect(() => {
    return () => {
      phonemeHook.cancelPhonemeRecognition();
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (phonemeHook.phonemes.length > 0) {
      if (selectedPhonemeIndex !== null) {
        let isCorrect =
          directMatches[selectedPhonemeIndex].original_phoneme ===
          phonemeHook.firstTranscribedPhoneme;
        const updatedDirectMatches = [...directMatches];
        updatedDirectMatches[selectedPhonemeIndex] = {
          ...updatedDirectMatches[selectedPhonemeIndex],
          transcribed_phoneme: phonemeHook.firstTranscribedPhoneme,
          is_correct: isCorrect,
        };
        setDirectPhonemeMatches(updatedDirectMatches);
        setIsLoading(false);
        setIsRecording(false);
      }
    }
  }, [phonemeHook, selectedPhonemeIndex]);

  const fetchPhonemeInstructions = async (
    referencePhoneme,
    userPronouncedPhoneme,
    phonemeIndex
  ) => {
    setInstructionsLoading({ ...instructionsLoading, [phonemeIndex]: true });
    try {
      const response = await PhonemeInstructorService.getPhonemeInstructions(
        referencePhoneme,
        userPronouncedPhoneme,
        language,
        settings.native_language
      );
      setPhonemeInstructions({
        ...phonemeInstructions,
        [phonemeIndex]: response.instructions,
      });
    } catch (error) {
      console.error("Error fetching phoneme instructions:", error);
    }
    setInstructionsLoading({ ...instructionsLoading, [phonemeIndex]: false });
  };

  const toggleInstructions = (phonemeIndex) => {
    setPhonemeInstructions({
      ...phonemeInstructions,
      [phonemeIndex]: undefined,
    });
  };

  return (
    <tbody className="dark:bg-zinc-900 bg-white divide-y dark:divide-zinc-700 divide-gray-200">
      {directPhonemeMatches.map((match, index) => (
        <tr key={index}>
          <td className="px-4 py-2 text-sm font-medium dark:text-zinc-100">
            <div className="flex items-center">
              <div className="w-6 flex justify-center text-lg">
                <span classname="italic">{match.original_phoneme}</span>
              </div>
              <div className="ml-2">
                {match.phoneme_name !== "Unknown" && (
                  <button
                    onClick={() => playSound(match.phoneme_name)}
                    disabled={match.phoneme_name === "Unknown"}
                  >
                    <FontAwesomeIcon
                      icon={faVolumeUp}
                      className="text-md dark:text-zinc-300 text-gray-500"
                    />
                  </button>
                )}
              </div>
              {match.phoneme_name === "Unknown" && (
                <div className="ml-[17px]"></div>
              )}
              <button
                className={`ml-2 rounded-full flex items-center justify-center transition duration-300 ease-in-out w-[24px] h-[24px] relative ${
                  isRecording && selectedPhonemeIndex === index
                    ? "bg-red-500 hover:bg-red-700 pulsating-halo"
                    : "bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-800"
                } ${isLoading && selectedPhonemeIndex === index ? "cursor-not-allowed bg-red-500" : "cursor-pointer"}`}
                onClick={() => toggleRecording(index)}
                disabled={isLoading && selectedPhonemeIndex === index}
              >
                {isLoading && selectedPhonemeIndex === index ? (
                  <svg
                    className="animate-spin h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    className={`text-md ${
                      isRecording && selectedPhonemeIndex === index
                        ? "text-white"
                        : "dark:text-gray-300 text-gray-600"
                    }`}
                  />
                )}
              </button>
            </div>
          </td>
          <td className="px-2 py-2 text-sm text-zinc-300">
            {match.is_correct ? (
              <span className="text-green-600 dark:text-green-400 font-medium">Excellent!</span>
            ) : (
              <>
                <div className="flex">
                  <div className="text-red-400 dark:text-red-600 font-medium text-lg w-[20px]">
                    {match.transcribed_phoneme}
                  </div>
                  {(!isLoading && !isRecording) ||
                  selectedPhonemeIndex !== index ? (
                    !phonemeInstructions[index] ? (
                      <div className="flex items-center">
                        <button
                          className="ml-2 text-zinc-300 text-sm underline"
                          onClick={() =>
                            fetchPhonemeInstructions(
                              match.original_phoneme,
                              match.transcribed_phoneme,
                              index
                            )
                          }
                        >
                          <Badge variant="secondary">View Hint</Badge>
                        </button>
                        {instructionsLoading[index] && (
                          <div className="ml-1 dark:text-zinc-300 text-gray-500 text-sm">
                            <svg
                              className="animate-spin h-4 w-4 dark:text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    ) : (
                      <button
                        className="ml-2 text-zinc-300 text-sm underline"
                        onClick={() => toggleInstructions(index)}
                      >
                        <Badge variant="secondary">
                          Hide Hint{" "}
                          <FontAwesomeIcon
                            icon={faChevronUp}
                            className="ml-2 text-[9px]"
                          />
                        </Badge>
                      </button>
                    )
                  ) : null}
                </div>

                {phonemeInstructions[index] && (
                  <ul className="mt-2">
                    {phonemeInstructions[index]
                      .split("\n")
                      .map((instruction, i) => (
                        <li key={i} className="relative flex gap-x-0 pb-2">
                          <div className="absolute left-0 top-0 flex w-6 justify-center bottom-0">
                            <div className="w-px dark:bg-zinc-600 bg-gray-300"></div>
                          </div>
                          <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                            <div className="absolute h-4 w-4 rounded-full dark:bg-zinc-700 bg-gray-300 flex items-center justify-center">
                              <span className="text-[9px] dark:text-white text-black">
                                {i + 1}
                              </span>
                            </div>
                          </div>
                          <div className="flex-auto py-0.5 text-sm leading-5 dark:text-gray-400 text-gray-700">
                            {instruction}
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export default PhonemeTableBody;

// hooks/useDebounce.js
import { useRef, useCallback } from 'react';

const useDebounce = (functionToDebounce, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunction = useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      functionToDebounce(...args);
    }, delay);
  }, [functionToDebounce, delay]);

  return debouncedFunction;
};

export default useDebounce;

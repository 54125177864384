import Recorder from "./recorder";

let microphoneStream; // stream from getUserMedia()
let rec; // Recorder.js object, don't assign here
let input; // MediaStreamAudioSourceNode we'll be recording

export async function startRecording({
  audioContext,
  errHandler,
  onStreamLoad,
}) {
  try {
    if (audioContext.state === "suspended") {
      await audioContext.resume();
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    if (onStreamLoad) {
      onStreamLoad();
    }

    microphoneStream = stream;
    input = audioContext.createMediaStreamSource(stream);

    // Ensure rec is a new instance of Recorder with the input
    rec = new Recorder(input);

    rec.record();

    return stream;
  } catch (err) {
    console.log(err);

    if (errHandler) {
      errHandler();
    }
  }
}

export function stopRecording({ exportWAV, wavCallback }) {
  if (rec && typeof rec.stop === 'function') {
    rec.stop();

    microphoneStream.getAudioTracks().forEach(track => track.stop());

    if (exportWAV && wavCallback) {
      rec.exportWAV(wavCallback);
    }

    rec.clear();
  } else {
    console.error("Recorder is not initialized or does not have a stop method.");
  }
}

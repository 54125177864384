import React from "react";
import { useTheme } from "next-themes";

const DonutChart = ({ percentage, size }) => {
  const { theme } = useTheme();

  const radius = size / 2 - 10;
  const strokeWidth = 8;
  const viewBoxSize = size;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const lightModeColors = [
    "#f87171", // Red
    "#fb923c", // OrangeRed
    "#fbbf24", // DarkOrange
    "#facc15", // Gold
    "#a3e635", // YellowGreen
    "#4ade80", // Green
  ];

  const darkModeColors = [
    "#dc2626", // Red
    "#ea580c", // OrangeRed
    "#d97706", // DarkOrange
    "#ca8a04", // Gold
    "#65a30d", // YellowGreen
    "#16a34a", // Green
  ];

  const getColor = (accuracy) => {
    const colors = theme === "light" ? lightModeColors : darkModeColors;

    if (accuracy >= 0 && accuracy < 17) {
      return colors[0];
    } else if (accuracy >= 17 && accuracy < 34) {
      return colors[1];
    } else if (accuracy >= 34 && accuracy < 51) {
      return colors[2];
    } else if (accuracy >= 51 && accuracy < 68) {
      return colors[3];
    } else if (accuracy >= 68 && accuracy < 85) {
      return colors[4];
    } else {
      return colors[5];
    }
  };

  const color = getColor(percentage);
  const strokeColor = theme === "light" ? "#cbd5e1" : "#94a3b8";

  return (
    <div className="relative flex items-center justify-center">
      <svg width={viewBoxSize} height={viewBoxSize} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
        <circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={radius}
          fill="none"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
        />
      </svg>
      <div className="absolute text-xl font-semibold">{`${percentage}%`}</div>
    </div>
  );
};

DonutChart.defaultProps = {
  size: 200,
};

export default DonutChart;
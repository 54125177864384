import { requestWithAuth } from "@/utils/authUtils";

const ReadingPositionService = {
  async upsertReadingPosition(
    collectionId,
    contentId,
    position,
    isCurrentlyActive
  ) {
    try {
      const url = `/reading-position/upsert`;
      const method = "POST";
      const data = { collectionId, contentId, position, isCurrentlyActive };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in upserting reading position:", error);
      throw error;
    }
  },

  async getActiveReadingPosition(collectionId) {
    try {
      const url = `/reading-position/active`;
      const method = "POST";
      const data = { collectionId };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in fetching active reading position:", error);
      throw error;
    }
  },

  async deleteReadingPositionByCollection(collectionId) {
    try {
      const url = `/reading-position/delete-by-collection`;
      const method = "DELETE";
      const data = { collectionId };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in deleting reading position by collection:", error);
      throw error;
    }
  },

  // New method for updating reading position by collection ID
  async updateReadingPositionByCollection(collectionId, updates) {
    try {
      const url = `/reading-position/update-by-collection/${collectionId}`;
      const method = "PATCH"; // Using PATCH as it's typically used for partial updates
      const data = updates; // This could include any combination of contentId, position, and isCurrentlyActive
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error(
        "Error in updating reading position by collection ID:",
        error
      );
      throw error;
    }
  },
};

export default ReadingPositionService;

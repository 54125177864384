// DisplayLanguage.js
import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import ThemeModeToggle from "@/components/ThemeModeToggle";
import InnerContainerLoader from "@/components/InnerContainerLoader";
import languageMap from "@/constants/languageMap";
import languageNames from "@/constants/languageNames";
import nativeLanguageNames from "@/constants/nativeLanguageNames";
import { numFreeTrialDays } from "@/constants/freeTrialDays";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  STRIPE_PLUS_PLAN_PAYMENT_URL,
  STRIPE_CUSTOMER_PORTAL_URL,
} from "@/constants/api";

import "/node_modules/flag-icons/css/flag-icons.min.css";

import useStore from "@/store";
import { initializeUserSettings } from "@/store/settingsStore";
import posthog from "posthog-js";

const Settings = () => {
  const { user } = useStore().user;

  const {
    settings,
    updateSettings,
    darkMode,
    setDarkMode,
    toggleSoundEffects,
    soundEffects,
  } = useStore().settings;

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialiseSettings = async () => {
      try {
        console.log("Initializing user settings...");
        await initializeUserSettings();
        setIsInitialized(true);
      } catch (error) {
        console.error("Failed to initialize user settings:", error);
      }
    };
    initialiseSettings();
  }, []);

  const handleLanguageChange = (newLanguage) => {
    // Update the native_language setting
    updateSettings({ native_language: newLanguage });
  };

  function mapLanguageToCountry(language) {
    console.log("Language:", language);
    // Default mapping for 'en' to 'gb'
    if (language === "en") {
      return "gb";
    }

    // Specific mappings for variations of English
    switch (language) {
      case "ja":
        return "jp";
      case "hi":
        return "in";
      case "en-us":
        return "us";
      case "en-gb":
      case "en-gb":
        return "gb";
      case "zh":
        return "cn";
      case "ar":
        return "sa";
      case "ko":
        return "kr";
      default:
        // If no specific mapping is found, return the first part of the language code
        // This assumes language codes are in the format of 'language-country'
        return language.split("-")[0];
    }
  }

  const handleSubscriptionButtonClick = () => {
    const url =
      user.subscription_type === "plus"
        ? STRIPE_CUSTOMER_PORTAL_URL
        : STRIPE_PLUS_PLAN_PAYMENT_URL;
    window.open(url, "_blank");
  };

  useEffect(() => {
    posthog.capture("settings_page_viewed");
  }, []);

  const getDaysRemaining = (createdAt) => {
    if (createdAt === undefined || createdAt === null) {
      return numFreeTrialDays;
    }

    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - createdDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const remainingDays = numFreeTrialDays - daysDiff; // Assuming the free trial is for 30 days
    return remainingDays >= 0 ? remainingDays : 0;
  };

  if (!isInitialized || !user) {
    return <InnerContainerLoader offset={12} />;
  }

  const subscriptionButtonLabel =
    user.subscription_type === "plus"
      ? "Manage Subscription"
      : "Upgrade to Plus";

  return (
    <div className="flex justify-center items-center md:mt-0 mt-24">
      <div className="dark:bg-dark-grey bg-zinc-50 p-4 rounded-lg max-w-xl w-full sm:w-1/3  shadow-sm">
        <h1 className="text-xl mb-4">Settings</h1>
        <br></br>
        <div className="flex justify-between items-center mb-4">
          <div className="dark:text-white w-[200%]">Native Language</div>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className="w-[160px] justify-between dark:bg-very-dark-grey border dark:border-very-dark-grey"
              >
                {nativeLanguageNames[settings.native_language] ||
                  "Select language..."}
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[160px] h-[50vh] p-0">
              <Command>
                <CommandInput
                  placeholder="Search language..."
                  className="h-9"
                />
                <CommandEmpty>No language found.</CommandEmpty>
                <CommandGroup className="overflow-scroll">
                  {Object.keys(nativeLanguageNames).map((languageCode) => (
                    <CommandItem
                      key={languageCode}
                      value={nativeLanguageNames[languageCode]}
                      onSelect={() => {
                        handleLanguageChange(languageCode.replace(/\_/g, "-"));
                      }}
                    >
                      {nativeLanguageNames[languageCode]}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          settings.native_language ===
                            languageCode.replace(/\_/g, "-")
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        {/* Sound Effects Switch */}
        <div className="flex justify-between items-center mb-4">
          <div className="dark:text-white">Sound Effects</div>
          <Switch checked={soundEffects} onCheckedChange={toggleSoundEffects} />
        </div>

        {/* Dark Mode Toggle Button */}
        <div className="flex justify-between items-center mb-4">
          <div>Theme</div>
          <ThemeModeToggle />
        </div>

        <br></br>
        <br></br>

        {user.subscription_type === "free_trial" &&
          getDaysRemaining(user.created_at) > 0 && (
            <div
              className="dark:bg-yellow-900 bg-yellow-200 dark:text-yellow-100 text-yellow-900 p-4 mb-4 rounded-lg"
              role="alert"
            >
              <p className="font-bold">Free Trial</p>
              <p>
                {getDaysRemaining(user.created_at) <= 1
                  ? "Your free trial is ending soon."
                  : `You have ${getDaysRemaining(user.created_at)} days remaining in your free trial.`}
              </p>
            </div>
          )}

        {user.subscription_type === "free_trial" &&
          getDaysRemaining(user.created_at) <= 0 && (
            <div
              className="bg-red-900 text-red-100 p-4 mb-4 rounded-lg"
              role="alert"
            >
              <p className="font-bold">Free Trial Ended</p>
              <p>
                Your free trial has ended. Please upgrade to the Plus plan to
                continue using the service.
              </p>
            </div>
          )}

        {user.subscription_type === "free" && (
          <div
            className="bg-gray-200 dark:bg-gray-800 text-gray-600 dark:text-gray-100 p-4 mb-4 rounded-lg"
            role="alert"
          >
            <p className="font-bold">Free Plan</p>
            <p>
              You are currently on the free plan. Consider upgrading to the Plus
              plan for additional features and benefits.
            </p>
          </div>
        )}

        {user.subscription_type === "plus" && (
          <div
            className="bg-green-200 dark:bg-green-900 text-green-950 dark:text-green-100 p-4 mb-4 rounded-lg"
            role="alert"
          >
            <p className="font-bold">Plus Plan</p>
            <p>
              You are subscribed to the Plus plan. Enjoy all the premium
              features and benefits!
            </p>
          </div>
        )}

        {/* Upgrade Plan Button */}
        <button
          onClick={handleSubscriptionButtonClick}
          className="w-full mt-3 mb-3 bg-green-700 hover:bg-green-800 text-white py-2 px-4 rounded-lg transition duration-300"
        >
          {subscriptionButtonLabel}
        </button>

        <br></br>
      </div>
    </div>
  );
};

export default Settings;

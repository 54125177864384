// src/services/DocumentService.js
import { requestWithAuth } from "@/utils/authUtils";
import ReadingPositionService from "@/services/ReadingPositionService";
import UserService from "@/services/UserService";

const DocumentService = {
  async createCollectionFromDocument(title, language, file) {
    try {
      const url = `/collections/import/document`;
      const method = "POST";
      const formData = new FormData();
      formData.append("title", title);
      formData.append("language", language);
      formData.append("file", file);
      const fetchedCollectionData = await requestWithAuth(url, method, formData);
      await UserService.updateCurrentCollection(fetchedCollectionData.id);
      await ReadingPositionService.upsertReadingPosition(
        fetchedCollectionData.id,
        null,
        0,
        true
      );
      return fetchedCollectionData;
    } catch (error) {
      console.error("Error in creating collection from document:", error);
      throw error;
    }
  },
};

export default DocumentService;
const onboardingCollections = {
  "en-us": {
    basic: "95d3eec5-e338-4c2a-8dc3-a40115b424dd",
    "tongue-twisters": "81ae670a-a8f1-49a8-91ad-1fb9b7ed9c02",
  },
  "en-gb": {
    basic: "95d3eec5-e338-4c2a-8dc3-a40115b424dd",
    "tongue-twisters": "81ae670a-a8f1-49a8-91ad-1fb9b7ed9c02",
  },
  "en-gb-x-rp": {
    basic: "95d3eec5-e338-4c2a-8dc3-a40115b424dd",
    "tongue-twisters": "81ae670a-a8f1-49a8-91ad-1fb9b7ed9c02",
  },

  de: {
    basic: "d64451bc-d5e3-4729-a460-188cb827cdf4",
    "tongue-twisters": "4bb8de55-7b63-4cc1-9569-73b7ced92134",
  },

  fr: {
    basic: "dca000be-ad9b-48bd-98d9-c85904324382",
    "tongue-twisters": "8edc88a2-7874-4b7e-8ee7-52a908b99db1",
  },
  "fr-be": {
    basic: "dca000be-ad9b-48bd-98d9-c85904324382",
    "tongue-twisters": "8edc88a2-7874-4b7e-8ee7-52a908b99db1",
  },
  "fr-ch": {
    basic: "dca000be-ad9b-48bd-98d9-c85904324382",
    "tongue-twisters": "8edc88a2-7874-4b7e-8ee7-52a908b99db1",
  },

  it: {
    basic: "383449b2-b7ac-4a02-9830-75ccd81bd2b6",
    "tongue-twisters": "0101f612-60da-40a8-9a40-88bdd76fc43f",
  },
  ja: {
    basic: "7cd5df90-4268-4a5b-a81c-0369133d5d0f",
    "tongue-twisters": "2a643b3f-4094-4c1e-b7dc-b7c1e7b94c30",
  },
  zh: {
    basic: "a1bb292f-6fc3-4255-99be-4a475c94092d",
    "tongue-twisters": "cec6cdcc-0358-46fa-9c46-d7ce8d887d9d",
  },
  hi: {
    basic: "95ccb560-3d27-4572-adf7-4316d4dac53e",
    "tongue-twisters": "7b8e3128-bb49-4e66-9fce-384234e15c8e",
  },

  es: {
    basic: "812f681e-e8d8-4e1e-88a2-d620fee7aa39",
    "tongue-twisters": "bd3c701e-5423-4c72-bbaf-92a77c8a7468",
  },
  "es-419": {
    basic: "812f681e-e8d8-4e1e-88a2-d620fee7aa39",
    "tongue-twisters": "bd3c701e-5423-4c72-bbaf-92a77c8a7468",
  },

  ru: {
    basic: "d5baefd6-0b82-4d63-b71b-d41aa121c403",
    "tongue-twisters": "ded89e25-c7a6-4b9a-830d-2c4841ffd69f",
  },

  pt: {
    basic: "c9617af9-5f70-4efc-97db-2f97dfd6623b",
    "tongue-twisters": "16622739-2e26-4290-8b61-7be574b0a38f",
  },
  "pt-br": {
    basic: "c9617af9-5f70-4efc-97db-2f97dfd6623b",
    "tongue-twisters": "16622739-2e26-4290-8b61-7be574b0a38f",
  },

  ar: {
    basic: "58b66d25-b866-49e6-ba73-9b30c27558b3",
    "tongue-twisters": "16ab8d27-7fe6-4c0a-845f-035f93687c0d",
  },
  ko: {
    basic: "14dd8ed1-2895-455f-9528-96a5dd5c3275",
    "tongue-twisters": "69fc7c64-fb36-402c-aec6-dff250c07ecf",
  },
};

export default onboardingCollections;

import { useCallback, useState, useEffect, useRef } from "react";
import languageMap from "@/constants/languageMap";
import useStore from "@/store";

const useSpeechSynthesis = (sentences, onEndOfSentences) => {
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [activeSentence, setActiveSentence] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [rate, setRate] = useState(1);
  const isListeningRef = useRef(isListening);

  const { currentCollection } = useStore().collections;

  useEffect(() => {
    isListeningRef.current = isListening;
  }, [isListening]);

  const speakCurrentSentence = useCallback(
    (index) => {
      if (index < sentences.length && isListeningRef.current) {
        const sentence = sentences[index];
        const utterance = new SpeechSynthesisUtterance(sentence);
        
        const convertedLanguageKey = currentCollection.language.replace(/-/g, "_");
        utterance.lang = languageMap[convertedLanguageKey] || "en-US";
        utterance.rate = rate;

        console.log('convertedLanguageKey', utterance.lang)

        utterance.onstart = () => {
          setActiveSentence(sentence);
        };

        utterance.onend = () => {
          if (isListeningRef.current) {
            const nextIndex = index + 1;
            if (nextIndex < sentences.length) {
              setCurrentSentenceIndex(nextIndex);
            } else {
              // End of sentences array reached
              setIsListening(false); // Stop listening to prevent infinite loop
              setCurrentSentenceIndex(0); // Reset index for next listening session
              onEndOfSentences?.(); // Call the callback if provided
            }
          }
        };

        speechSynthesis.speak(utterance);
      }
    },
    [sentences, currentCollection, onEndOfSentences]
  );

  useEffect(() => {
    if (isListening) {
      speakCurrentSentence(currentSentenceIndex);
    } else {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    }
  }, [isListening, currentSentenceIndex, speakCurrentSentence]);

  const startListening = (rate = 1) => {
    setRate(rate);
    setIsListening(true);
    setCurrentSentenceIndex(0); // Ensure we start from the first sentence
  };

  const stopListening = () => setIsListening(false);

  return { activeSentence, isListening, startListening, stopListening };
};

export default useSpeechSynthesis;

import React, { useEffect, useState, useCallback, useRef } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Slider } from "@/components/ui/slider";
import ReadingPositionService from "@/services/ReadingPositionService";
import { useNavigate } from "react-router-dom";
import TextWall from "@/components/LearningSession/TextWall/TextWall";
import useSpeechToText from "@/speechToText";
import { usePhonemeRecognition } from "@/speechToText/usePhonemeRecognition";
import SessionResults from "@/components/LearningSession/SessionResults";
import CommandMenu from "@/components/LearningSession/CommandMenu";
import { Button } from "@/components/ui/button";
import SessionControls from "@/components/LearningSession/SessionControls";
import ControlButtons from "@/components/LearningSession/ControlButtons";
import YoutubeVideoController from "@/components/LearningSession/YoutubeVideoController";
import useTimer from "@/hooks/useTimer";
import useStore from "@/store";
import InnerContainerLoader from "@/components/InnerContainerLoader";
import useDebounce from "@/hooks/useDebounce";
import useSpeechSynthesis from "@/hooks/useSpeechSynthesis";
import languageMap from "@/constants/languageMap";
import posthog from "posthog-js";

import "./LearningSession.css";
import { set } from "date-fns";

const successSound = new Audio("/correct.mp3");

const isMobileMode = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth <= 768;
  }
  return false;
};

function LearningSession() {
  const navigate = useNavigate();
  const { user } = useStore().user;
  const { soundEffects } = useStore().settings;
  const {
    collections,
    fetchCollections,
    setCurrentCollectionById,
    collectionContents,
    currentCollection,
    currentContent,
    setCurrentContent,
  } = useStore().collections;

  const {
    fetchActiveReadingPosition,
    readingPosition,
    updateReadingPosition,
    updatePositionInState,
    upsertReadingPosition,
  } = useStore().learningSessionConfig;

  const learningSessionRef = useRef(null);
  const { elapsedTime, startTiming, pauseTiming, stopAndLogTiming } =
    useTimer();

  const [elapsedPhonemeTime, setElapsedPhonemeTime] = useState(0);

  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("mode");
    return savedMode ? savedMode : "phoneme";
  });

  const [open, setOpen] = React.useState(false);
  const [displayResults, setDisplayResults] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [noCollectionsFound, setNoCollectionsFound] = useState(false);
  const [collectionsFetched, setCollectionsFetched] = useState(false);

  const [continousPlaybackEnabled, setContinuousPlaybackEnabled] =
    useState(false);

  const [sliderValue, setSliderValue] = useState(readingPosition.position);

  const {
    error,
    interimResult,
    isRecording,
    results,
    resultText,
    startSpeechToText,
    stopSpeechToText,
    resetSpeakingSession,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    speechRecognitionProperties: {
      interimResults: true,
      lang: languageMap[currentCollection?.language] || "en-US",
      maxAlternatives: 4,
    },
    useLegacyResults: false,
  });

  const phonemeHook = usePhonemeRecognition();

  const { activeSentence, isListening, startListening, stopListening } =
    useSpeechSynthesis([currentContent?.text] || []);

  const [accuracy, setAccuracy] = useState(0); // Assuming you have a way to calculate this
  const [wordsPerMinute, setWordsPerMinute] = useState(0);
  const [totalWords, setTotalWords] = useState(0); // Total words in the transcribed text
  const [fullRepeatCount, setFullRepeatCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldReplay, setShouldReplay] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const updateCurrentCollectionIndexInBackend = useCallback(
    async (newIndex) => {
      try {
        if (!user.current_collection_id) {
          return;
        }
        await ReadingPositionService.updateReadingPositionByCollection(
          user.current_collection_id,
          { position: newIndex }
        );
      } catch (error) {
        console.error("Failed to update user settings:", error);
      }
    },
    [user?.current_collection_id]
  );

  const debouncedUpdateIndex = useDebounce(
    updateCurrentCollectionIndexInBackend,
    1000
  );

  const handleVideoEndReached = () => {
    handleCollectionNavigation("next");
  };

  useEffect(() => {
    localStorage.setItem("mode", mode);
  }, [mode]);

  useEffect(() => {
    // Ensure the slider updates its position if the readingPosition updates elsewhere
    setSliderValue(readingPosition.position);
  }, [readingPosition.position]);

  useEffect(() => {
    const fetchAndSetCollections = async () => {
      if (user && user.id) {
        await fetchCollections(user.id);
        setCollectionsFetched(true);
      }
    };

    fetchAndSetCollections();
  }, [user, fetchCollections]);

  useEffect(() => {
    if (collectionsFetched) {
      setNoCollectionsFound(collections.length === 0);
    }
  }, [collectionsFetched]);

  useEffect(() => {
    handleReset();
  }, [mode]);

  useEffect(() => {
    handleReset();
    setIsLoading(true);

    const fetchAndSetCurrentCollection = async () => {
      if (!user?.current_collection_id || collections.length === 0) {
        return;
      }

      console.log(
        "fetching reading position for user.current_collection_id",
        user.current_collection_id
      );

      try {
        const fetchedReadingPosition = await fetchActiveReadingPosition(
          user.current_collection_id
        );
        const collectionContents = await setCurrentCollectionById(
          user.current_collection_id
        );

        if (
          fetchedReadingPosition === undefined ||
          collectionContents === undefined
        ) {
          setIsLoading(false);
          return;
        }

        const clampedPosition = Math.max(
          0,
          Math.min(
            fetchedReadingPosition.position,
            collectionContents.length - 1
          )
        );

        if (clampedPosition !== fetchedReadingPosition.position) {
          updatePositionInState(clampedPosition);
        }

        setCurrentContent(clampedPosition);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch current collection:", error);
        setIsLoading(false);
      }
    };

    if (user?.current_collection_id) {
      fetchAndSetCurrentCollection();
    } else {
      setIsLoading(false);
    }
  }, [user?.current_collection_id, collections]);

  useEffect(() => {
    if (mode === "realtime") {
      return;
    }
    if (phonemeHook.phonemes.length === 0 && displayResults) {
      setDisplayResults(false);
    }
    if (phonemeHook.phonemes.length > 0 && !displayResults) {
      setDisplayResults(true);
      setAccuracy(phonemeHook.overallAccuracy);
      stopAndLogTiming();
      setElapsedPhonemeTime(phonemeHook.elapsedTime);
      setWordsPerMinute(phonemeHook.adjustedWpm);
      setFullRepeatCount(phonemeHook.fullRepeatCount);

      // Check if the floor of overallAccuracy is 100 and play the success sound
      if ((Math.floor(phonemeHook.overallAccuracy) === 100) && soundEffects) {
        successSound
          .play()
          .catch((error) => console.error("Failed to play the sound:", error));
      }
    }
  }, [phonemeHook]);

  useEffect(() => {
    const finalText = results
      .map((result) => result.transcript.trim())
      .join(" ");
    const wordCount = finalText.split(/\s+/).length;
    setTotalWords(wordCount);
  }, [results]);

  // Handler to trigger replay from ControlButtons
  const handleReplay = useCallback(() => {
    setShouldReplay(true);
  }, []);

  // Reset shouldReplay flag after replay action is initiated
  useEffect(() => {
    if (shouldReplay) {
      setShouldReplay(false);
    }
  }, [shouldReplay]);

  const calculateWPMAndAccuracy = useCallback(
    (numCorrectWords) => {
      const minutesElapsed = elapsedTime / 60;
      const wpm = numCorrectWords / minutesElapsed;
      setWordsPerMinute(wpm);
    },
    [elapsedTime]
  );

  useEffect(() => {
    const processRecognition = async () => {
      // if (isProcessing && interimResult === undefined) {
      if (isProcessing) {
        // Execute the code that should run after interimResult is cleared
        if (mode === "realtime") {
          await stopSpeechToText();
        } else if (mode === "phoneme") {
          await phonemeHook.stopPhonemeRecognition();
        }
        setIsProcessing(false); // End processing
      }
    };

    processRecognition();
  }, [isProcessing, phonemeHook]);

  const handleReset = () => {
    stopAndLogTiming();
    // setActiveSentence("");
    stopListening();
    setDisplayResults(false);
    phonemeHook.cancelPhonemeRecognition();

    if (isRecording || phonemeHook.isRecording) {
      if (mode === "realtime") {
        stopSpeechToText();
      }
    }
    resetSpeakingSession();
  };

  const handleCollectionNavigation = (navigationInput) => {
    let newIndex;
    if (typeof navigationInput === "number") {
      // Direct index selection from the slider
      newIndex = navigationInput;
    } else {
      // Prev/Next button navigation
      const currentIndex = readingPosition.position;
      const maxIndex = collectionContents.length - 1;
      if (navigationInput === "prev" && currentIndex > 0) {
        newIndex = currentIndex - 1;
      } else if (navigationInput === "next" && currentIndex < maxIndex) {
        newIndex = currentIndex + 1;
      }
    }

    if (newIndex !== undefined) {
      // Update the index in the state immediately
      updatePositionInState(newIndex);
      setCurrentContent(newIndex);
      setSliderValue(newIndex);

      // Debounce the backend update
      debouncedUpdateIndex(newIndex);

      handleReset();
    }

    setDisplayResults(false);
    stopAndLogTiming();
  };

  const handleStartStopRecording = () => {
    posthog.capture("recording_toggled");
    if (displayResults) {
      handleReset();
    }
    if (isRecording || phonemeHook.isRecording) {
      setIsProcessing(true);
      pauseTiming();
    } else {
      startTiming();
      if (mode === "realtime") {
        startSpeechToText();
      } else if (mode === "phoneme") {
        phonemeHook.startPhonemeRecognition(
          currentContent.text,
          currentCollection.language,
          currentContent.repeatable
        );
      }
    }
  };

  const attemptToPauseRecording = (
    accuracy,
    originalWords,
    transcribedWords,
    wrongWords
  ) => {
    stopAndLogTiming();
    calculateWPMAndAccuracy(originalWords.length - wrongWords.length);
    setAccuracy(accuracy);

    setDisplayResults(true);
    // If currently recording, set processing to true
    if (isRecording || phonemeHook.isRecording) {
      setIsProcessing(true);
    }
  };

  if (noCollectionsFound) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center space-y-4">
          <p className="text-muted-foreground">
            No collections found. Please add a collection to get started.
          </p>
          <Button
            className="bg-green-500 hover:bg-green-400 dark:bg-green-500 dark:hover:bg-green-400"
            onClick={() => navigate("/collections/new")}
          >
            + Create
          </Button>
        </div>
      </div>
    );
  }
  if (!currentCollection || !currentContent || !readingPosition) {
    return <InnerContainerLoader offset={0} />;
  }

  return (
    <div
      ref={learningSessionRef}
      className="flex w-full flex-col md:pt-[10vh] items-center justify-center h-[100svh] mobile-safe-area"
    >
      <div className={`relative flex flex-col md:w-full w-[100svw] flex-grow`}>
        {readingPosition.collection_id && (
          <SessionControls mode={mode} setMode={setMode} />
        )}
        {currentCollection.content_type === "youtube" &&
          !isLoading &&
          readingPosition && (
            <div className="flex items-center justify-center md:mb-12 mb-5 md:mt-0 mt-16 max-h-[40vh]">
              <YoutubeVideoController
                parentRef={learningSessionRef}
                shouldReplay={shouldReplay}
                isVideoPlaying={isVideoPlaying}
                setIsVideoPlaying={setIsVideoPlaying}
                handleNext={handleVideoEndReached}
                continousPlaybackEnabled={continousPlaybackEnabled}
                setContinuousPlaybackEnabled={setContinuousPlaybackEnabled}
                handleCollectionNavigation={handleCollectionNavigation}
              />
            </div>
          )}

        <div
          className={`flex flex-1 w-full flex-col items-center md:pl-1 md:pr-1 pl-3 pr-3 ${
            currentCollection.content_type === "youtube" && isMobileMode()
              ? ""
              : "justify-center"
          }`}
        >
          <div
            className={`${isLoading ? "overflow-hidden" : "overflow-auto"} break-words relative mt-3 pl-6 pr-6 pt-3 pb-3 flex w-full items-center justify-center ${currentCollection.content_type !== "youtube" ? "max-h-[50svh]" : "md:max-h-[10svh] max-h-[25svh]"} md:mt-0 mt-12 ${
              !isLoading ? "dark:bg-dark-grey md:rounded-md rounded-md" : ""
            }`}
            style={{
              boxShadow: !isLoading ? "0 0 1px rgba(0, 0, 0, 0.2)" : "none",
            }}
          >
            {isLoading ? (
              <div>
                <InnerContainerLoader offset={0} />
              </div>
            ) : (
              <TextWall
                text={currentContent?.text}
                activeSentence={activeSentence}
                transcribedSpeechText={results
                  .map((result) => result.transcript.trim())
                  .join(" ")}
                interimTranscribedSpeechText={interimResult?.trim() || ""}
                endOfTextReached={attemptToPauseRecording}
                phonemes={phonemeHook.phonemes}
                mode={mode}
              />
            )}
          </div>
        </div>
      </div>

      {isMobileMode() && (
        <div className="flex justify-center absolute bottom-36 left-1/2 transform -translate-x-1/2 w-[95%]">
          {displayResults && (
            <Card className="pl-6 pr-6">
              <SessionResults
                wordsPerMinute={
                  mode === "realtime"
                    ? ((currentContent?.text ?? "")
                        .trim()
                        .split(/\s+/)
                        .filter(Boolean).length *
                        (accuracy / 100)) /
                      (elapsedTime / 60)
                    : wordsPerMinute
                }
                accuracy={accuracy}
                elapsedTime={
                  mode === "realtime" ? elapsedTime : elapsedPhonemeTime
                }
                repeatable={currentContent?.repeatable}
                fullRepeatCount={fullRepeatCount}
              />
            </Card>
          )}
        </div>
      )}

      <div className="md:mt-8 mt-4">
        {!isLoading && (
          <Slider
            step={1}
            max={collectionContents.length - 1}
            defaultValue={[readingPosition.position]}
            value={[sliderValue]}
            onValueChange={(value) => handleCollectionNavigation(value[0])}
            className="mx-auto"
            // className={cn("w-[60%]")}
            // {...props}
          />
        )}

        <div className="flex items-center justify-center mt-6 md:mb-0 mb-12">
          <ControlButtons
            mode={mode}
            onPrev={() => handleCollectionNavigation("prev")}
            onNext={() => handleCollectionNavigation("next")}
            endOfCollectionReached={
              readingPosition.position === collectionContents.length - 1
            }
            onReset={handleReset}
            onStartStopRecording={handleStartStopRecording}
            isRecording={isRecording || phonemeHook.isRecording}
            onStartStopListening={(rate) => {
              if (isListening) {
                stopListening();
              } else {
                startListening(rate);
              }
            }}
            isListening={isListening}
            isPredicting={phonemeHook.isPredicting}
            handleReplay={handleReplay}
            isVideoPlaying={isVideoPlaying}
            setIsVideoPlaying={setIsVideoPlaying}
          />
        </div>
      </div>

      {!isMobileMode() && (
        <div className="h-[200px] flex items-center">
          {displayResults && (
            <Card className="pl-6 pr-6">
              <SessionResults
                wordsPerMinute={
                  mode === "realtime"
                    ? ((currentContent?.text ?? "")
                        .trim()
                        .split(/\s+/)
                        .filter(Boolean).length *
                        (accuracy / 100)) /
                      (elapsedTime / 60)
                    : wordsPerMinute
                }
                accuracy={accuracy}
                elapsedTime={
                  mode === "realtime" ? elapsedTime : elapsedPhonemeTime
                }
                repeatable={currentContent?.repeatable}
                fullRepeatCount={fullRepeatCount}
              />
            </Card>
          )}
        </div>
      )}

      <div className="opacity-50 fixed bottom-0 pb-9 right-0 pr-9 md:block hidden">
        <p className="text-sm text-muted-foreground">
          Actions{" "}
          <kbd className="bg-gray-200 dark:bg-gray-700 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
            <span className="text-xs">⌘</span>K
          </kbd>
        </p>
        <CommandMenu open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}

export default LearningSession;

import { useState, useEffect, useRef } from "react";
import { BACKEND_API_URL } from "@/constants/api";
import { getIdToken } from "@/utils/authUtils";
import PracticeSessionStatsService from "@/services/PracticeSessionStatService";
import { set } from "date-fns";
import useStore from "@/store";
import { toast } from "sonner";

let isCanceled = false;

export const usePhonemeRecognition = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [phonemes, setPhonemes] = useState([]);
  const [overallAccuracy, setOverallAccuracy] = useState(0);
  const [adjustedWpm, setAdjustedWpm] = useState(0);
  const [fullRepeatCount, setFullRepeatCount] = useState(0);
  const [error, setError] = useState("");
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const [activeSentence, setActiveSentence] = useState("");
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isPredicting, setIsPredicting] = useState(false);
  const [transcribedPhonemesList, setTranscribedPhonemesList] = useState([]);
  const [directMatches, setDirectMatches] = useState([]);
  const [firstTranscribedPhoneme, setFirstTranscribedPhoneme] = useState("");

  const { user } = useStore().user;
  const { currentContent } = useStore().collections;
  const { settings } = useStore().settings;

  const streamRef = useRef(null);

  useEffect(() => {
    // Clean up resources when the component using this hook unmounts
    return () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      // Stop the media stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const cancelPhonemeRecognition = () => {
    setIsRecording(false);
    setPhonemes([]);
    setElapsedTime(0);
    setError("");
    // Clear the recorded audio data
    recordedChunksRef.current = [];

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      isCanceled = true;
      mediaRecorderRef.current.stop();
      // Stop the media stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      // Immediately reset relevant states to ensure the component reflects the cancellation

      console.log("Phoneme recognition cancelled and audio data cleared.");
    }
  };

  const startPhonemeRecognition = async (
    sentence,
    language,
    repeatable,
    save = true
  ) => {
    setIsRecording(true);
    setPhonemes([]);
    setError("");
    recordedChunksRef.current = [];
    setActiveSentence(sentence);

    let startTime, endTime; // Declare startTime and endTime

    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaRecorderRef.current = new MediaRecorder(streamRef.current);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstart = () => {
        // Capture the start time when the recording starts
        startTime = Date.now();
        console.log("Recording started");
      };

      mediaRecorderRef.current.onstop = async () => {
        if (isCanceled) {
          // Reset the cancellation flag
          isCanceled = false;
          // Early return or handle the cancel-specific logic here
          return;
        }
        endTime = Date.now();
        const elapsedTime = (endTime - startTime) / 1000;
        setElapsedTime(elapsedTime);

        const audioBlob = new Blob(recordedChunksRef.current, {
          type: "audio/wav",
        }); // Note: The actual MIME type might differ
        console.log("Recording stopped, processing data...");

        try {
          const formData = new FormData();
          formData.append("file", audioBlob, "recording.wav");
          formData.append("source_text", sentence);
          formData.append("language", language);
          formData.append("repeatable", repeatable);

          const token = await getIdToken();
          setIsPredicting(true);

          const response = await fetch(`${BACKEND_API_URL}/transcribe`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });

          setIsPredicting(false);

          if (response.status === 403) {
            toast.error("Feature Unavailable", {
              description:
                "This feature is not available in the free tier. Please upgrade to the Plus plan, or use the real-time model (only available in Chrome on desktop).",
              action: {
                label: "Close",
                onClick: () => {
                  // Handle upgrade logic here
                  console.log("Upgrade button clicked");
                },
              },
            });
          }

          if (!response.ok) throw new Error("Failed to transcribe");

          const data = await response.json();
          setPhonemes(data.segments || []);
          setOverallAccuracy(data.overall_accuracy || 0);
          setFullRepeatCount(data.full_repeat_count || 0);
          setTranscribedPhonemesList(data.transcribed_phonemes_list || []);
          setDirectMatches(data.direct_matches || []);

          setFirstTranscribedPhoneme(data.first_transcribed_phoneme || "");

          let accuracyRate = data.overall_accuracy / 100;
          let rawWpm = data.segments.length / (elapsedTime / 60);
          // Multiply raw WPM by accuracy rate
          let adjustedWpm = rawWpm * accuracyRate;
          setAdjustedWpm(rawWpm * accuracyRate);

          if (save) {
            PracticeSessionStatsService.createPracticeSessionStat(
              currentContent.id,
              user.current_collection_id,
              language,
              adjustedWpm,
              data.overall_accuracy,
              data.segments.length,
              data.segments.map((phoneme) => phoneme.text),
              data.segments,
              [],
              elapsedTime
            );
          }
        } catch (err) {
          setError("Error processing phonemes: " + err.message);
        }
      };

      mediaRecorderRef.current.start();
    } catch (err) {
      setError("Could not start recording: " + err.message);
      setIsRecording(false);
    }
  };

  const stopPhonemeRecognition = () => {
    setPhonemes([]);
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      // Stop the media stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  return {
    isRecording,
    phonemes,
    overallAccuracy,
    setOverallAccuracy,
    adjustedWpm,
    elapsedTime,
    fullRepeatCount,
    firstTranscribedPhoneme,
    transcribedPhonemesList,
    directMatches,
    setDirectMatches,
    error,
    startPhonemeRecognition,
    stopPhonemeRecognition,
    cancelPhonemeRecognition,
    isPredicting,
    setPhonemes,
  };
};

import React, { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Shuffle, Repeat1, Library } from "lucide-react";
import { Badge } from "@/components/ui/badge";

import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import TooltipWrapper from "@/components/TooltipWrapper";

import UserService from "@/services/UserService";

import useStore from "@/store";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import "@/components/TopBarStyles.css";

const SessionControls = ({ mode, setMode }) => {
  const [open, setOpen] = React.useState(false);
  const [isChromeDesktop, setIsChromeDesktop] = useState(false);

  const { user, setCurrentCollectionId } = useStore().user;
  const { collections, currentCollection } = useStore().collections;
  const { settings, updateSettings } = useStore().settings;
  const [isMobile, setIsMobile] = useState(false);
  const { setDisableShortcuts } = useStore().learningSessionConfig;

  useEffect(() => {
    setDisableShortcuts(open);
  }, [open]);

  React.useEffect(() => {
    const down = (e) => {
      if (e.key === "c" && !e.ctrlKey && !e.metaKey && !open) {
        e.preventDefault();
        setOpen(true);
      }
      if (e.key === "Escape") {
        e.preventDefault();
        setOpen(false);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [setOpen]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isChrome = /Chrome/i.test(userAgent);
    const isDesktop =
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );
    setIsMobile(!isDesktop);
    setIsChromeDesktop(isChrome && isDesktop);
  }, []);

  function mapLanguageToCountry(language) {
    // Default mapping for 'en' to 'gb'
    if (language === "en") {
      return "gb";
    }

    // Specific mappings for variations of English
    switch (language) {
      case "ja":
        return "jp";
      case "hi":
        return "in";
      case "en-us":
        return "us";
      case "en-gb":
      case "en-gb":
        return "gb";
      case "zh":
        return "cn";
      case "ar":
        return "sa";
      case "ko":
        return "kr";
      default:
        // If no specific mapping is found, return the first part of the language code
        // This assumes language codes are in the format of 'language-country'
        return language.split("-")[0];
    }
  }

  return (
    <div className="md:relative w-full fixed flex items-center mt-auto h-16 md:pl-0 pl-8 pr-2 sm:shadow-md md:shadow-none dark:bg-zinc-900 custom-dark-bg-reset">
      <div className="flex w-full justify-center justify-between">
        <div className="flex items-center">
          {/* <Library strokeWidth={0.75} size={20} /> */}
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="md:w-[240px] w-[220px] justify-between ml-4 md:dark:bg-very-dark-grey border dark:border-very-dark-grey"
                onFocus={(e) => e.target.blur()}
              >
                {user.current_collection_id ? (
                  <>
                    {window.innerWidth < 768
                      ? collections
                          .find(
                            (item) => item.id === user.current_collection_id
                          )
                          ?.title.slice(0, 24) +
                        (collections.find(
                          (item) => item.id === user.current_collection_id
                        )?.title.length > 24
                          ? "..."
                          : "")
                      : collections.find(
                          (item) => item.id === user.current_collection_id
                        )?.title}
                  </>
                ) : (
                  "Select collections..."
                )}
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="md:w-[240px] w-[240px] p-0">
              <Command>
                <CommandInput
                  placeholder="Search collection..."
                  className="h-9"
                />
                <CommandEmpty>No collection found.</CommandEmpty>
                <CommandGroup className="overflow-scroll h-[60vh]">
                  {collections.map((collection) => (
                    <CommandItem
                      key={collection.id}
                      value={collection.title}
                      onSelect={(currentValue) => {
                        setCurrentCollectionId(collection.id);
                        UserService.updateCurrentCollection(collection.id);
                        setOpen(false);
                      }}
                    >
                      {collection.title}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          user.current_collection_id === collection.id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        <div className="flex">
          {/* Mode Dropdown */}
          {/* <div className="flex items-center">{language.toUpperCase()}</div> */}
          {/* <span
            className={`fi fi-${mapLanguageToCountry(currentCollection.language)} md:mr-2 mr-4`}
          ></span> */}
          <div className="flex justify-center items-center mr-2">
            <span>
              <Badge variant="secondary">
                {currentCollection.language.toUpperCase()}
              </Badge>
            </span>
          </div>
          {/* <span>{currentCollection.language}</span> */}
          {!isMobile && (
            <Select defaultValue={mode} onValueChange={setMode}>
              <SelectTrigger
                className="md:w-[130px] w-[100px]"
                onFocus={(e) => e.target.blur()}
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="realtime">Real-time</SelectItem>
                <SelectItem value="phoneme">Phoneme</SelectItem>
              </SelectContent>
            </Select>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionControls;

import React from "react";
import { useNavigate } from "react-router-dom";
import CollectionEditor from "@/components/Collections/CollectionEditor";
import CollectionService from "@/services/CollectionService";
import { toast } from "sonner";

const CreateCollection = () => {
  const navigate = useNavigate();

  const onSave = async (collectionDetails) => {
    console.log("Saving new collection with details:", collectionDetails);
    // Here, implement your logic to save the collection
    try {
      // Assume collectionDetails contains { title, contents }
      await CollectionService.createCollectionWithContents(
        collectionDetails.title,
        collectionDetails.language,
        collectionDetails.contents,
        collectionDetails.type,
        collectionDetails.youtubeUrl
      );

      toast("Success", {
        description: "The collection was created successfully!",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
      navigate("/collections");
    } catch (error) {
      console.error("Failed to create collection:", error);
    }
  };

  return <CollectionEditor collection={null} />;
};

export default CreateCollection;

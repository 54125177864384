import { requestWithAuth } from "@/utils/authUtils";

const TranslateService = {
  async translateText(text, sourceLang, targetLang) {
    try {
      const url = "/translate";
      const method = "POST";
      const body = { text, source_lang: sourceLang, target_lang: targetLang };
      const headers = {
        "Content-Type": "application/json",
      };
      return await requestWithAuth(url, method, body, headers);
    } catch (error) {
      console.error("Error in translating text:", error);
      throw error;
    }
  },
};

export default TranslateService;

import create from "zustand";

const useUserStore = create((set) => ({
  user: null, // Initially, there's no user
  onboardingEnabled: false, // Initially, onboarding is disabled
  onboardingStages: {
    speakButtonClicked: false,
    wordClicked: false,
    // Add more onboarding stages as needed
  },

  // Action to set the entire user object
  setUser: (user) => set({ user }),

  // Action to update the current_collection_id of the user
  setCurrentCollectionId: (currentCollectionId) =>
    set((state) => ({
      user: {
        ...state.user, // Spread the existing user properties
        current_collection_id: currentCollectionId, // Update current_collection_id
      },
    })),

  // Action to enable/disable onboarding
  setOnboardingEnabled: (enabled) => set({ onboardingEnabled: enabled }),

  // Action to update a specific onboarding stage
  setOnboardingStage: (stage, completed) =>
    set((state) => ({
      onboardingStages: {
        ...state.onboardingStages, // Spread the existing onboarding stages
        [stage]: completed, // Update the specified stage
      },
    })),
}));

export default useUserStore;
// src/services/ContentService.js
import { requestWithAuth } from "@/utils/authUtils";

const ContentService = {
  async getContentByCollectionId(collectionId) {
    try {
      const url = `/contents?collectionId=${collectionId}`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching content by collection ID:", error);
      throw error;
    }
  },
};

export default ContentService;

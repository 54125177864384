import { devtools } from "zustand/middleware";
import useSettingsStore from "@/store/settingsStore";
import useCollectionsStore from "@/store/collectionsStore";
import useUserStore from "@/store/userStore";
import useLearningSessionConfigStore from "@/store/learningSessionConfigStore";
import useOnboardingStore from "@/store/onboardingStore";

let useStore = () => ({
  settings: useSettingsStore(),
  collections: useCollectionsStore(),
  user: useUserStore(),
  learningSessionConfig: useLearningSessionConfigStore(),
  onboarding: useOnboardingStore(),
});

export default useStore;

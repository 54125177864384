import create from "zustand";
import CollectionService from "@/services/CollectionService";
import ContentService from "@/services/ContentService";

const useCollectionsStore = create((set, get) => ({
  collections: [],
  currentCollection: null,
  collectionContents: [],
  currentContent: null,
  setCollections: (collections) => set({ collections }),
  fetchCollections: async (userId) => {
    try {
      const userCollections =
        await CollectionService.getUserCollections(userId);
      set({ collections: userCollections });
    } catch (error) {
      console.error("Failed to fetch user collections:", error);
    }
  },

  setCurrentCollection: (collection) => {
    set({ currentCollection: collection });
  },
  setCollectionContents: (collectionContents) => {
    set({ collectionContents });
  },
  
  setCurrentCollectionById: async (collectionId) => {
    const collections = get().collections;
    const currentCollection = collections.find(
      (collection) => collection.id === collectionId
    );
    set({ currentCollection });

    try {
      const collectionContents =
        await ContentService.getContentByCollectionId(collectionId);
      set({ collectionContents });
      return collectionContents;
    } catch (error) {
      console.error("Failed to fetch collection contents:", error);
    }
  },
  
  setCurrentContent: (currentCollectionIndex) => {
    const collectionContents = get().collectionContents;

    let adjustedIndex = currentCollectionIndex;
    if (currentCollectionIndex >= collectionContents.length) {
      adjustedIndex = 0;
    }

    const currentContent = collectionContents[adjustedIndex];
    set({ currentContent });
  },
}));

export default useCollectionsStore;

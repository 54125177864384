import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyABRbehU9YyxIElnxnRq4U7MqGcC8YqC0c",
    authDomain: "langcraft-85187.firebaseapp.com",
    projectId: "langcraft-85187",
    storageBucket: "langcraft-85187.appspot.com",
    messagingSenderId: "291906276647",
    appId: "1:291906276647:web:45d37698dc857b5aabed02",
    measurementId: "G-1YCKVMW81C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
// export const db = getFirestore(app);
export const auth = () => getAuth(app);


import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import nativeLanguageNames from "@/constants/nativeLanguageNames";

function OnboardingAlertDialog({ open, setOpen, onStart }) {
  const [selectedNativeLanguage, setSelectedNativeLanguage] = useState("en"); // Rename to reflect its purpose
  const [selectedLanguage, setSelectedLanguage] = useState("en-us"); // Start with an empty state
  const [selectedType, setSelectedType] = useState("basic");
  const [step, setStep] = useState(1); // New state to control the display of selections

  const handleNativeLanguageChange = (value) => {
    setSelectedNativeLanguage(value);
    setStep(2); // Move to the next step
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    // You might want to automatically move to the next step or require user action
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const handleStart = () => {
    // Ensure that onStart can handle all required selections
    onStart(selectedNativeLanguage, selectedLanguage, selectedType);
    setOpen(false);
  };

  const handleNext = () => {
    // Only proceed to next step if a native language is selected
    if (selectedNativeLanguage) {
      setStep(2);
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="dark:border-green-950 border-green-500">
        <AlertDialogHeader>
          <AlertDialogTitle>Welcome to SpeechCraft!</AlertDialogTitle>
        </AlertDialogHeader>
        {step === 1 && (
          <div className="mt-4">
            <div className="mb-3">Select your native language</div>
            <Select
              value={selectedNativeLanguage}
              defaultValue={selectedNativeLanguage}
              onValueChange={handleNativeLanguageChange}
            >
              <SelectTrigger className="w-full dark:bg-very-dark-grey">
                <SelectValue placeholder="Select native language" />
              </SelectTrigger>
              <SelectContent>
                {Object.keys(nativeLanguageNames).map((languageCode) => (
                  <SelectItem
                    key={languageCode}
                    value={languageCode.replace(/\_/g, "-")}
                  >
                    {nativeLanguageNames[languageCode]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {step === 2 && (
          <>
            <div className="mt-4">
              <div className="mb-3">
                Select a sample collection to start with
              </div>
              <div className="flex">
                <Select
                  value={selectedLanguage}
                  onValueChange={handleLanguageChange}
                >
                  <SelectTrigger className="w-[150px] dark:bg-very-dark-grey mr-1">
                    <SelectValue placeholder="Select the collection's language" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="en-us">English - US</SelectItem>
                    <SelectItem value="en-gb">English - UK</SelectItem>
                    <SelectItem value="en-gb-x-rp">
                      English - Received Pronunciation
                    </SelectItem>

                    <SelectItem value="fr">French</SelectItem>
                    <SelectItem value="fr-be">French - Belgium</SelectItem>
                    <SelectItem value="fr-ch">French - Switzerland</SelectItem>

                    <SelectItem value="de">German</SelectItem>
                    {/* <SelectItem value="ja">Japanese</SelectItem> */}
                    <SelectItem value="hi">Hindi</SelectItem>

                    <SelectItem value="es">Spanish</SelectItem>
                    <SelectItem value="es-419">
                      Spanish - Latin American
                    </SelectItem>

                    {/* <SelectItem value="zh">Chinese</SelectItem> */}
                    <SelectItem value="it">Italian</SelectItem>
                    <SelectItem value="ru">Russian</SelectItem>

                    <SelectItem value="pt">Portuguese</SelectItem>
                    <SelectItem value="pt-br">Portuguese - Brazil</SelectItem>

                    <SelectItem value="ar">Arabic</SelectItem>
                    <SelectItem value="ko">Korean</SelectItem>
                  </SelectContent>
                </Select>
                <Select value={selectedType} onValueChange={handleTypeChange}>
                  <SelectTrigger className="w-full dark:bg-very-dark-grey">
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="basic">Introductory Phrases</SelectItem>
                    <SelectItem value="tongue-twisters">
                      Tongue Twisters
                    </SelectItem>
                    {/* <SelectItem value="none">Prefer not to</SelectItem> */}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </>
        )}
        <AlertDialogFooter>
          {/* <AlertDialogCancel onClick={() => setOpen(false)}>
            Cancel
          </AlertDialogCancel> */}
          {step === 1 && (
            <Button
              className="bg-green-500 hover:bg-green-500 border-green-700 text-black"
              onClick={handleNext}
            >
              Next
            </Button>
          )}
          {step === 2 && (
            <AlertDialogAction
              onClick={handleStart}
              className="bg-green-500 hover:bg-green-500 border-green-700 text-black"
            >
              Start
              {/* <Button
                className="dark:bg-blue-500 dark:hover:bg-blue-700 w-full"
                onClick={handleStart}
                disabled={!selectedLanguage || !selectedType}
              >
                Start
              </Button> */}
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default OnboardingAlertDialog;

import { getAuth } from "firebase/auth";
import { BACKEND_API_URL } from "@/constants/api";
import axios from "axios";

export async function getIdToken() {
  const user = getAuth().currentUser;
  if (user) {
    const token = await user.getIdToken();
    return token;
  } else {
    return null;
  }
}

export async function requestWithAuth(
  url,
  method = "POST",
  body = null,
  dateRange = {}
) {
  const token = await getIdToken();

  let data;
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  if (body instanceof FormData) {
    // If body is FormData, set the content type to multipart/form-data
    headers["Content-Type"] = "multipart/form-data";
    data = body;
  } else {
    // If body is not FormData, stringify it and set the content type to application/json
    headers["Content-Type"] = "application/json";
    data = JSON.stringify({ ...body, dateRange });
  }

  const config = {
    method,
    url: `${BACKEND_API_URL}${url}`,
    headers,
    data,
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error in requestWithAuth:", error);
    throw error;
  }
}

// src/services/CollectionService.js
import { requestWithAuth } from "@/utils/authUtils";
import ReadingPositionService from "@/services/ReadingPositionService";
import UserService from "@/services/UserService";

const CollectionService = {
  async getUserCollections() {
    try {
      const url = `/collections`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching collections:", error);
      throw error;
    }
  },

  async createCollection(language, title) {
    try {
      const url = `/collections`;
      const method = "POST";
      const data = { language, title };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in creating collection:", error);
      throw error;
    }
  },

  async copyCollection(templateCollectionId, language) {
    try {
      const url = `/collections/copy`;
      const method = "POST";
      const data = { template_collection_id: templateCollectionId, language: language };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in copying collection:", error);
      throw error;
    }
  },

  async getCollectionById(collectionId) {
    try {
      const url = `/collections/${collectionId}`;
      const method = "GET";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in fetching collection:", error);
      throw error;
    }
  },

  async updateCollection(
    collectionId,
    language,
    title,
    originalContents,
    contents,
    type,
    youtubeUrl
  ) {
    try {
      const url = `/collections/${collectionId}`;
      const method = "PUT";
      console.log(
        "updateCollection",
        language,
        title,
        originalContents,
        contents,
        type,
        youtubeUrl
      );
      const data = {
        language,
        title,
        originalContents,
        contents,
        type,
        youtubeUrl,
      };
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in updating collection:", error);
      throw error;
    }
  },

  async deleteCollection(collectionId) {
    try {
      const url = `/collections/${collectionId}`;
      const method = "DELETE";
      return await requestWithAuth(url, method);
    } catch (error) {
      console.error("Error in deleting collection:", error);
      throw error;
    }
  },

  async updateCollectionContents(collectionId, contents) {
    try {
      const url = `/collections/${collectionId}/contents`;
      const method = "PUT";
      const data = { contents }; // The request body includes an array of content items
      return await requestWithAuth(url, method, data);
    } catch (error) {
      console.error("Error in updating collection contents:", error);
      throw error;
    }
  },

  async createCollectionWithContents(
    title,
    language,
    contents,
    type,
    youtubeUrl,
    numSubtitlesToMerge = 1
  ) {
    try {
      const url = `/collections`; // Adjust URL based on your API endpoint
      const method = "POST";
      console.log(
        "createCollectionWithContents",
        language,
        title,
        contents,
        type,
        youtubeUrl
      );
      const data = {
        language,
        title,
        contents,
        type,
        youtubeUrl,
        numSubtitlesToMerge,
      };
      const fetchedCollectionData = await requestWithAuth(url, method, data);

      await UserService.updateCurrentCollection(fetchedCollectionData.id);
      await ReadingPositionService.upsertReadingPosition(
        fetchedCollectionData.id,
        null,
        0,
        true
      );

      return fetchedCollectionData;
    } catch (error) {
      console.error("Error in creating collection with contents:", error);
      throw error;
    }
  },
};

export default CollectionService;

import create from "zustand";
import UserService from "@/services/UserService";

const useSettingsStore = create((set) => ({
  language: "en", // default language
  settings: null, // Placeholder for user settings
  currentCollectionId: null,
  soundEffects: localStorage.getItem('soundEffects') === 'true' || true, // Get the value from local storage, default to false if not found

  toggleSoundEffects: () => {
    set((state) => {
      const newSoundEffects = !state.soundEffects;
      localStorage.setItem('soundEffects', newSoundEffects); // Update local storage
      return { soundEffects: newSoundEffects };
    });
  },


  setCurrentCollectionId: (collectionId) =>
    set({ currentCollectionId: collectionId }),
  setLanguage: (language) => set({ language }),
  setSettings: (newSettings) => set({ settings: newSettings }),
  updateSettings: async (newSettings) => {
    // Update local state
    set((state) => ({
      settings: { ...state.settings, ...newSettings },
    }));

    // Update backend
    try {
      const userSettingsUpdated =
        await UserService.updateUserSettings(newSettings);
      console.log("Settings updated successfully", userSettingsUpdated);
    } catch (error) {
      console.error("Failed to update user settings:", error);
      // Optionally revert local state if backend update fails
    }
  },
}));

export const initializeUserSettings = async () => {
  try {
    const response = await UserService.getUserSettings();
    useSettingsStore.getState().setSettings(response); // Populate the settings in the store
  } catch (error) {
    console.error("Error initializing user settings:", error);
  }
};

export default useSettingsStore;

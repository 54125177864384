import React from "react";
import {
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getAuth,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import logo from "@/assets/logo_noborder.png";

const handleGoogleSignup = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  // Enhanced device and browser checks
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isMobileChrome = isMobile && /Chrome/i.test(navigator.userAgent);
  const isMobileFirefox = isMobile && /Firefox/i.test(navigator.userAgent);

  // Prefer signInWithRedirect for most mobile browsers due to varied support for popups
  if (isMobile && !isIOS && (!isMobileChrome || isMobileFirefox)) {
    signInWithRedirect(auth, provider);
  } else {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Authentication error:", error);
      toast.error(error.message);
      if (!isIOS) {
        signInWithRedirect(auth, provider);
      }
    }
  }
};

const handleSignup = async (event) => {
  event.preventDefault(); // Prevent the form from submitting the traditional way

  const email = event.target.email.value;
  const password = event.target.password.value;
  const auth = getAuth();

  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    // User has been successfully created
    console.log("User created:", userCredential.user);
    toast.success("Signup successful!");
    // Optionally, redirect the user or show a success message
    //   navigate("/some-success-route"); // Adjust the route as needed
  } catch (error) {
    console.log("Error signing up:", error.message);
    toast.error(error.message);
    // Handle errors here, such as showing an error message to the user
  }
};

const Signup = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-[100vh] flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white dark:bg-very-dark-grey">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={logo} alt="SpeechCraft" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
          Create an account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="px-6 py-12. sm:px-12">
          <form className="space-y-6" onSubmit={handleSignup}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="bg-white dark:bg-very-2-dark-grey block w-full rounded-md border-0 py-2 px-3 text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-green-600 dark:focus:ring-green-800 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="bg-white dark:bg-very-2-dark-grey block w-full rounded-md border-0 py-2 px-3 text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-green-600 dark:focus:ring-green-800 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Sign up
              </button>
            </div>
          </form>

          {/* <div className="mt-4 text-center">
            <span
              className="font-semibold text-green-600 hover:text-green-500 cursor-pointer"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password?
            </span>
          </div> */}

          <div>
            <div className="relative mt-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200 dark:border-gray-900"></div>
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white dark:bg-very-dark-grey px-6 text-gray-900 dark:text-gray-100">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 grid gap-4" onClick={handleGoogleSignup}>
              <a
                href="#"
                className="dark:bg-darker-grey flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-900 hover:bg-gray-50 dark:hover:bg-gray-900 focus-visible:ring-transparent"
              >
                <svg className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </a>
            </div>
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already have an account?{" "}
          <Link
            to="/login"
            className="font-semibold leading-6 text-green-600 hover:text-green-500"
          >
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;

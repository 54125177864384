import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import YouTube from "react-youtube";
import useStore from "@/store";

const YoutubeVideoController = ({
  parentRef,
  shouldReplay,
  isVideoPlaying,
  setIsVideoPlaying,
  handleNext,
  handleCollectionNavigation,
}) => {
  const { user } = useStore().user;
  const { collectionContents, currentCollection } = useStore().collections;
  const { settings } = useStore().settings;
  const { pauseVideo, continueVideo } = useStore().learningSessionConfig;
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(() => {
    const savedValue = localStorage.getItem("autoPlayEnabled");
    return savedValue ? JSON.parse(savedValue) : false;
  });

  const {
    disableShortcuts,
    wordPracticeDialogOpened,
    fetchActiveReadingPosition,
    readingPosition,
    updateReadingPosition,
    updatePositionInState,
  } = useStore().learningSessionConfig;

  const urlParams = new URLSearchParams(
    new URL(currentCollection.video_url).search
  );
  const [videoId, setVideoId] = useState(urlParams.get("v"));
  const playerRef = useRef(null);
  const prevCollectionIndexRef = useRef(null);

  // Calculate initial player dimensions
  const calculatePlayerDimensions = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth < 768) {
      // Assuming mobile if width is less than 768px
      let screenRatio = 1;
      return {
        width: screenWidth * screenRatio,
        height: screenWidth * screenRatio * (9 / 16),
      };
    } else {
      const height = screenHeight * 0.42;
      const width = height * (16 / 9);

      return {
        width,
        height,
      };
    }
  };

  const [playerDimensions] = useState(calculatePlayerDimensions());

  // Options for the YouTube player
  const opts = useMemo(
    () => ({
      height: playerDimensions.height.toString(),
      width: playerDimensions.width.toString(),
      playerVars: {
        autoplay: 0,
        controls: 1,
        disablekb: 1,
        fs: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        rel: 0,
      },
    }),
    [playerDimensions]
  );

  const timeStringToSeconds = useMemo(
    () => (timeString) => {
      const parts = timeString.split(":");
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const secondsParts = parts[2].split(".");
      const seconds = parseInt(secondsParts[0], 10);
      const milliseconds = secondsParts[1] ? parseInt(secondsParts[1], 10) : 0;
      return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    },
    []
  );

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.pauseVideo();
    }
  }, [pauseVideo]);

  useEffect(() => {
    if (playerRef && playerRef.current) {
      const endTime = timeStringToSeconds(
        collectionContents[readingPosition.position].end_time
      );
      const currentTime = playerRef.current.getCurrentTime();

      if (currentTime < endTime) {
        playerRef.current.playVideo();
      }
    }
  }, [continueVideo]);

  useEffect(() => {
    localStorage.setItem("autoPlayEnabled", JSON.stringify(autoPlayEnabled));
  }, [autoPlayEnabled]);

  useEffect(() => {
    const urlParams = new URLSearchParams(
      new URL(currentCollection.video_url).search
    );
    setVideoId(urlParams.get("v"));
  }, [currentCollection.video_url]);

  // Replay functionality
  useEffect(() => {
    if (shouldReplay && playerRef.current) {
      // Logic to restart the segment
      if (
        readingPosition.position >= 0 &&
        readingPosition.position < collectionContents.length
      ) {
        const startTimeInSeconds = timeStringToSeconds(
          collectionContents[readingPosition.position].start_time
        );
        try {
          playerRef.current.seekTo(startTimeInSeconds, true);
          playerRef.current.playVideo();
        } catch (error) {
          console.log("Failed to restart segment:", error);
        }
      }
    }
  }, [shouldReplay]);

  const onReady = (event) => {
    playerRef.current = event.target;

    if (collectionContents.length > 0 && readingPosition.position >= 0) {
      if (!collectionContents[readingPosition.position].start_time) {
        return;
      }
      const startTimeInSeconds = timeStringToSeconds(
        collectionContents[readingPosition.position].start_time
      );

      if (playerRef.current) {
        try {
          playerRef.current.seekTo(startTimeInSeconds, true);
          playerRef.current.playVideo();
        } catch (error) {
          console.log("Failed to seek to timestamp:", error);
        }
      }

      prevCollectionIndexRef.current = readingPosition.position;
    }
  };

  const onUnmount = useCallback((event) => {
    event.target.destroy();
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      if (autoPlayEnabled) {
        playerRef.current.playVideo();
      } else {
        playerRef.current.pauseVideo();
      }
    }
  }, [autoPlayEnabled]);

  useEffect(() => {
    const checkFocusInterval = setInterval(() => {
      const activeElement = document.activeElement;
      const iframeElement = document.querySelector("iframe");
      if (iframeElement && activeElement === iframeElement) {
        // Attempt to blur the iframe
        iframeElement.blur();
        // Then, set focus to the document's body
        document.body.focus();
      }
    }, 500);

    return () => clearInterval(checkFocusInterval);
  }, []);

  useEffect(() => {
    let syncIntervalId = null;

    if (autoPlayEnabled) {
      syncIntervalId = setInterval(() => {
        const player = playerRef.current;
        if (player && player.getPlayerState() === YouTube.PlayerState.PLAYING) {
          const currentTime = player.getCurrentTime();
          const nextPosition = collectionContents.findIndex(
            (content) =>
              timeStringToSeconds(content.start_time) <= currentTime &&
              timeStringToSeconds(content.end_time) > currentTime
          );

          if (
            nextPosition !== -1 &&
            nextPosition !== readingPosition.position
          ) {
            handleCollectionNavigation(nextPosition);
          }
        }
      }, 100);
    }

    return () => {
      if (syncIntervalId) {
        clearInterval(syncIntervalId);
      }
    };
  }, [autoPlayEnabled, collectionContents, readingPosition.position]);

  useEffect(() => {
    if (
      collectionContents.length > 0 &&
      readingPosition.position >= 0 &&
      playerRef.current
    ) {
      if (
        autoPlayEnabled &&
        readingPosition.position - prevCollectionIndexRef.current > 0
      ) {
        const startTimeInSeconds = timeStringToSeconds(
          collectionContents[readingPosition.position].start_time
        );
        const currentTime = playerRef.current.getCurrentTime();

        if (startTimeInSeconds - currentTime < 0.2) {
          prevCollectionIndexRef.current = readingPosition.position;
          return;
        }
      }
      if (readingPosition.position !== prevCollectionIndexRef.current) {
        const startTimeInSeconds = timeStringToSeconds(
          collectionContents[readingPosition.position].start_time
        );

        if (playerRef.current) {
          try {
            playerRef.current.seekTo(startTimeInSeconds, true);
            playerRef.current.playVideo();
          } catch (error) {
            console.log("Failed to seek to timestamp:", error);
          }
        }

        prevCollectionIndexRef.current = readingPosition.position;
      }
    }
  }, [readingPosition.position, collectionContents]);

  useEffect(() => {
    if (playerRef.current) {
      const currentState = playerRef.current.getPlayerState();
      const currentTime = playerRef.current.getCurrentTime();
      const endTimeInSeconds =
        readingPosition.position >= 0 &&
        readingPosition.position < collectionContents.length
          ? timeStringToSeconds(
              collectionContents[readingPosition.position].end_time
            )
          : 0;

      if (
        isVideoPlaying &&
        currentState !== YouTube.PlayerState.PLAYING &&
        currentTime < endTimeInSeconds
      ) {
        playerRef.current.playVideo();
      } else if (
        !isVideoPlaying &&
        currentState === YouTube.PlayerState.PLAYING
      ) {
        playerRef.current.pauseVideo();
      } else if (
        currentState === YouTube.PlayerState.PAUSED &&
        currentTime >= endTimeInSeconds
      ) {
        handleNext();
      }
    }
  }, [isVideoPlaying]);

  const goToTimestamp = (index) => {
    if (index < 0 || index >= collectionContents.length || !playerRef.current)
      return;
    const startTimeInSeconds = timeStringToSeconds(
      collectionContents[index].start_time
    );
    if (playerRef.current) {
      try {
        playerRef.current.seekTo(startTimeInSeconds, true);
        playerRef.current.playVideo();
      } catch (error) {
        console.log("Failed to seek to timestamp:", error);
      }
    }
  };

  const checkPlaybackIntervalRef = useRef(null);

  const clearPlaybackCheckInterval = () => {
    if (checkPlaybackIntervalRef.current !== null) {
      clearInterval(checkPlaybackIntervalRef.current);
      checkPlaybackIntervalRef.current = null;
    }
  };

  const onStateChange = (event) => {
    switch (event.data) {
      case YouTube.PlayerState.PLAYING:
        setIsVideoPlaying(true);
        break;
      case YouTube.PlayerState.PAUSED:
      case YouTube.PlayerState.ENDED:
        setIsVideoPlaying(false);
        break;
      default:
        break;
    }

    const player = event.target;
    clearPlaybackCheckInterval(); // Clear any existing interval

    if (
      readingPosition.position >= 0 &&
      readingPosition.position < collectionContents.length
    ) {
      const endTimeInSeconds = timeStringToSeconds(
        collectionContents[readingPosition.position].end_time
      );

      const checkPlaybackPosition = () => {
        const currentTime = player.getCurrentTime();
        if (player.getPlayerState() === YouTube.PlayerState.PLAYING) {
          if (currentTime >= endTimeInSeconds) {
            clearPlaybackCheckInterval(); // Clear the interval once condition is met
            if (!autoPlayEnabled) {
              setIsVideoPlaying(false); // Update state to reflect that video is not playing
            }
          }
        }
      };

      // Set a new interval
      checkPlaybackIntervalRef.current = setInterval(
        checkPlaybackPosition,
        100
      );
    }
    if (parentRef.current) {
      parentRef.current.focus(); // Focus the parent container
      const iframe = document.querySelector("iframe");
      iframe.blur();
    }
  };

  useEffect(() => {
    // Cleanup interval on component unmount
    return () => clearPlaybackCheckInterval();
  }, []);

  const togglePlayPause = () => {
    const playerState = playerRef.current.getPlayerState();
    if (playerState === YouTube.PlayerState.PLAYING) {
      playerRef.current.pauseVideo();
    } else {
      playerRef.current.playVideo();
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (wordPracticeDialogOpened || disableShortcuts) return;
      if (e.key === "ArrowDown") {
        togglePlayPause();
      } else if (e.key === "ArrowUp") {
        // Logic to restart the segment
        if (
          readingPosition.position >= 0 &&
          readingPosition.position < collectionContents.length
        ) {
          const startTimeInSeconds = timeStringToSeconds(
            collectionContents[readingPosition.position].start_time
          );
          try {
            playerRef.current.seekTo(startTimeInSeconds, true);
            playerRef.current.playVideo();
          } catch (error) {
            console.log("Failed to restart segment:", error);
          }
        }
      }
    };

    // Add event listener for key press
    window.addEventListener("keydown", handleKeyPress); // Use "keydown" to capture "ArrowUp"

    // Cleanup event listener
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [readingPosition.position, collectionContents]); // Include dependencies that handleKeyPress uses

  if (!videoId) {
    return <div>Invalid YouTube URL</div>;
  }

  return (
    <div
      className="relative rounded-xl"
      style={{ width: playerDimensions.width, height: playerDimensions.height }}
    >
      <React.Suspense fallback={<div>Loading...</div>}>
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={onReady}
          onStateChange={onStateChange}
          onUnmount={onUnmount}
        />
      </React.Suspense>

      <div className="absolute -bottom-6 right-0 flex items-center space-x-2">
        <Label htmlFor="autoplay-switch">AP</Label>
        <Switch
          id="autoplay-switch"
          checked={autoPlayEnabled}
          onCheckedChange={setAutoPlayEnabled}
        />
      </div>
    </div>
  );
};

export default YoutubeVideoController;

import React, { useState, useEffect } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "@/components/ui/popover";
import * as Popover from '@radix-ui/react-popover';
import { MixerHorizontalIcon, Cross2Icon } from '@radix-ui/react-icons';
import './popover_styles.css';


const TooltipWrapper = ({
  children,
  tooltipText,
  onboarding = false,
  onboardingMessage = "",
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const isDisabled = true;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (onboarding) {
    return (
      <Popover.Root defaultOpen>
    <Popover.Trigger asChild>
      {children}
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content className="PopoverContent" sideOffset={5}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, zIndex: 999999 }}>
          {/* <p className="Text" style={{ marginBottom: 10 }}>
            Let's get started!
          </p> */}
          <p className="Text" style={{ marginBottom: 10 }}>
            {onboardingMessage}
          </p>
        </div>
        <Popover.Close className="PopoverClose" aria-label="Close">
          <Cross2Icon />
        </Popover.Close>
        <Popover.Arrow className="PopoverArrow" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
    );
  }

  return (
    <TooltipProvider>
      {!isMobile && !isDisabled ? (
        <Tooltip>
          <TooltipTrigger>{children}</TooltipTrigger>
          <TooltipContent>
            <p>{tooltipText}</p>
          </TooltipContent>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </TooltipProvider>
  );
};

export default TooltipWrapper;
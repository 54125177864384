// useTimer.js
import { useState, useRef } from 'react';

const useTimer = () => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const startTimeRef = useRef(null);
  const pauseTimeRef = useRef(null);

  const startTiming = () => {
    if (pauseTimeRef.current) {
      const pauseDuration = Date.now() - pauseTimeRef.current;
      startTimeRef.current += pauseDuration;
      pauseTimeRef.current = null;
    } else {
      startTimeRef.current = Date.now();
    }
  };

  const pauseTiming = () => {
    pauseTimeRef.current = Date.now();
  };

  const stopAndLogTiming = () => {
    if (startTimeRef.current) {
      const endTime = Date.now();
      const elapsed = (endTime - startTimeRef.current) / 1000;
      setElapsedTime(elapsed);
      startTimeRef.current = null;
      pauseTimeRef.current = null;
    }
  };

  return { elapsedTime, startTiming, pauseTiming, stopAndLogTiming };
};

export default useTimer;

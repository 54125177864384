import React, { useState, useEffect } from "react";
import firebase from "@/firebaseConfig";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LearningSession from "@/components/LearningSession/LearningSession";
import Home from "@/components/Home";
import CollectionsHome from "@/components/Collections/CollectionsHome";
import Sidebar from "@/components/Sidebar";
import Profile from "@/components/Profile";
import Settings from "@/components/Settings";
import Signup from "@/components/Signup";
import CreateCollection from "@/components/Collections/CreateCollection";
import EditCollection from "@/components/Collections/EditCollection";
import Stats from "@/components/Stats";
import OnboardingAlertDialog from "@/components/OnboardingAlertDialog";
import { ThemeProvider } from "@/components/ThemeProvider";
import Login from "@/components/Login";
import { getAuth } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";
import UserService from "@/services/UserService";
import { Bars } from "react-loader-spinner";
import onboardingCollections from "@/constants/onboardingCollections";
import useStore from "@/store";
import { initializeUserSettings } from "@/store/settingsStore";

import posthog from "posthog-js";
import CollectionService from "@/services/CollectionService";
import ForgotPassword from "@/components/ForgotPassword";

const Layout = ({ children }) => {
  return <div className="md:mt-24 mt-0">{children}</div>;
};

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [onboardingInProgress, setOnboardingInProgress] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setUser, setCurrentCollectionId, setOnboardingEnabled } = useStore().user;
  const { updateSettings } = useStore().settings;
  const { setIsOnboarding } = useStore().onboarding;
  const { setCurrentCollection, setCollectionContents, setCollections } =
    useStore().collections;

  const handleStart = async (nativeLanguage, language, type) => {
    // Perform the copy collection action based on the selected language and type
    console.log(`Starting with language: ${language} and type: ${type}`);
    try {
      let selectedCollection = onboardingCollections[language][type];
      const copiedCollection =
        await CollectionService.copyCollection(selectedCollection, language);
      UserService.updateUserSettings({ native_language: nativeLanguage });
      updateSettings({ native_language: nativeLanguage });

      setCollections([copiedCollection]);
      setCurrentCollection(copiedCollection);
      setCurrentCollectionId(copiedCollection.id);
      setOnboardingInProgress(false);
      console.log("set current collection id", copiedCollection.id);
    } catch (error) {
      console.error("Error in copying collection:", error);
      // Handle the error appropriately (e.g., show a message to the user)
    }
  };

  useEffect(() => {
    const auth = getAuth();
    // await authStateReady(auth);

    auth.authStateReady().then(() => {
      const user = auth.currentUser;
      if (user) {
        const userData = {
          id: user.uid,
          email: user.email,
          display_name: user.displayName,
          username: user.email,
          login_provider: user.providerData[0].providerId,
        };

        setCurrentUser(user);
        setUser(userData);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        const userData = {
          id: user.uid,
          email: user.email,
          display_name: user.displayName,
          username: user.email,
          login_provider: user.providerData[0].providerId,
        };

        const createdMinutesAgo =
          (Date.now() - user.metadata.createdAt) / (1000 * 60);
        if (createdMinutesAgo <= 1) {
          try {
            const fetchedUser = await UserService.upsertUser(userData);
            if (fetchedUser.is_new_user) {
              setIsOnboardingOpen(true);
              setOnboardingInProgress(true);
              setIsOnboarding(true);
              setOnboardingEnabled(true);
              setLoading(false);
            }
            setUser(fetchedUser);
            setCurrentUser(fetchedUser);
          } catch (error) {
            console.error("Failed to upsert user:", error);
            // Save the JSON form of the Firebase user object to the user store
            setUser(userData);
            setCurrentUser(user);
            setLoading(false);
          }
        } else {
          // setIsOnboardingOpen(true);
          // setOnboardingInProgress(true);
          // setIsOnboarding(true);
          // setOnboardingEnabled(true);
          // setLoading(false);

          setLoading(false);
          setCurrentUser(user);
          try {
            const fetchedUser = await UserService.upsertUser(userData);
            setUser(fetchedUser);
          } catch (error) {
            console.error("Failed to upsert user:", error);
            // Save the JSON form of the Firebase user object to the user store
            setUser(userData);
          }
        }

        try {
          posthog.identify(user.uid, {
            email: user.email,
            name: user.displayName,
          });

          posthog.capture("user_signed_in");
          await initializeUserSettings();

          setLoading(false);
        } catch (error) {
          console.error("Failed to initialize user settings:", error);
          setLoading(false);
        }
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if ((!currentUser && loading) || onboardingInProgress) {
    return (
      <ThemeProvider attribute="class" defaultTheme="dark">
        <div className="dark:very-dark-grey w-[100vw] h-[100vh] flex justify-center items-center">
          <Bars
            height="20"
            width="20"
            color="#22c55e"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <OnboardingAlertDialog
            open={isOnboardingOpen}
            setOpen={setIsOnboardingOpen}
            onStart={handleStart}
          />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider attribute="class" defaultTheme="dark">
      <Router>
        <div className="flex">
          {currentUser && <Sidebar />}
          <div className={`flex-1 ${currentUser ? "md:ml-64 md:mr-64" : ""}`}>
            <Routes>
              <Route
                path="/"
                element={
                  currentUser ? <Home /> : <Navigate replace to="/login" />
                }
              />
              <Route
                path="/login"
                element={currentUser ? <Navigate to="/" /> : <Login />}
              />
              <Route
                path="/signup"
                element={currentUser ? <Navigate to="/" /> : <Signup />}
              />
              <Route
                path="/forgot-password"
                element={currentUser ? <Navigate to="/" /> : <ForgotPassword />}
              />
              <Route
                path="/"
                element={
                  currentUser ? <LearningSession /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/collections"
                element={
                  currentUser ? (
                    <Layout>
                      <CollectionsHome />
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/collections/new"
                element={
                  currentUser ? (
                    <Layout>
                      <CreateCollection />
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />

              <Route
                path="/collections/:collectionId/edit"
                element={
                  currentUser ? (
                    <Layout>
                      <EditCollection />
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/stats"
                element={
                  currentUser ? (
                    <Layout>
                      <Stats />
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/profile"
                element={
                  currentUser ? (
                    <Layout>
                      <Profile />
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/settings"
                element={
                  currentUser ? (
                    <Layout>
                      <Settings />
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />

              <Route
                path="/sessions/:sessionId"
                element={
                  currentUser ? <LearningSession /> : <Navigate to="/login" />
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

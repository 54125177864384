import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CollectionEditor from "@/components/Collections/CollectionEditor";
import CollectionService from "@/services/CollectionService";
import { toast } from "sonner";
import { Bars } from "react-loader-spinner";
import InnerContainerLoader from "../InnerContainerLoader";

const EditCollection = () => {
  const { collectionId } = useParams();
  const [collection, setCollection] = useState(null);

  useEffect(() => {
    const fetchAndSetCollection = async () => {
      try {
        const data = await CollectionService.getCollectionById(collectionId);
        setCollection(data);
      } catch (error) {
        console.error("Error fetching collection:", error);
      }
    };

    fetchAndSetCollection();
  }, [collectionId]);

  return (
    <div>
      {collection ? (
        <CollectionEditor collection={collection} />
      ) : (
        <InnerContainerLoader offset={12} />
      )}
    </div>
  );
};

export default EditCollection;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Collections from "./Collections";
import CollectionService from "@/services/CollectionService";
import { Button } from "@/components/ui/button";
import CreateCollectionDialog from "@/components/Collections/CreateCollectionDialog";
import "@/components/TopBarStyles.css";
import InnerContainerLoader from "@/components/InnerContainerLoader";

const CollectionsHome = () => {
  const navigate = useNavigate();
  const [collections, setCollections] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const createCollection = async (newTitle) => {
    try {
      const response = await CollectionService.createCollection("en", newTitle);
      navigate(`/collections/${response.id}/edit`);
    } catch (error) {
      console.error("Failed to create new collection:", error);
    }
  };

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await CollectionService.getUserCollections();
        setCollections(response);
      } catch (error) {
        console.error("Failed to fetch collections:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after the request is completed
      }
    };
    fetchCollections();
  }, []);

  const handleCollectionClick = (collectionId) => {
    navigate(`/collections/${collectionId}/edit`);
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <div className="md:relative fixed w-full flex items-center md:mt-auto h-16 md:pl-0 pl-14 pr-4 sm:shadow-md md:shadow-none dark:bg-zinc-900 custom-dark-bg-reset">
        <h1 className="text-xl">Collections</h1>
      </div>
      <div className="pl-6 pr-6">
        <div className="mb-8 md:mt-8 md:pt-0 pt-[96px]">
          <Button
            className="dark:bg-green-500 bg-green-500 dark:hover:bg-green-600 hover:bg-green-600"
            onClick={openDialog}
          >
            + Create
          </Button>
        </div>
        <div style={{ maxHeight: "calc(100vh - 20rem)", overflowY: "auto" }}>
          {isLoading ? (
            <InnerContainerLoader offset={32} />
          ) : (
            <Collections
              collections={collections}
              onCollectionClick={handleCollectionClick}
            />
          )}
        </div>
        <CreateCollectionDialog
          open={isDialogOpen}
          onClose={closeDialog}
          onCreateCollection={createCollection}
        />
      </div>
    </div>
  );
};

export default CollectionsHome;
// DocumentImportDialog.js
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTitle, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import DocumentService from "@/services/DocumentService";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import posthog from "posthog-js";
import languageMap from "@/constants/languageMap";
import { ReloadIcon } from "@radix-ui/react-icons";
import useStore from "@/store";
import languageNames from "@/constants/languageNames";

import "/node_modules/flag-icons/css/flag-icons.min.css";

const DocumentImportDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { setCurrentCollectionId } = useStore().user;
  const { setCurrentCollectionById } = useStore().collections;
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("Untitled");
  const [language, setLanguage] = useState("en-us");
  const [isCreating, setIsCreating] = useState(false);

  function mapLanguageToCountry(language) {
    // ... mapLanguageToCountry function implementation ...
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCreateCollection = async () => {
    if (!file) {
      toast("Error", {
        description: "Please select a file to upload.",
        action: {
          label: "Close",
          onClick: () => console.log("Error Close"),
        },
      });
      return;
    }

    try {
      setIsCreating(true);
      const collectionDetails = {
        title,
        language,
        file,
        type: "document",
        contents: [],
      };

      const createdCollection =
        await DocumentService.createCollectionFromDocument(
          collectionDetails.title,
          collectionDetails.language,
          collectionDetails.file
        );

      toast("Success", {
        description: "The collection was created successfully!",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
      posthog.capture("collection_created");
      await setCurrentCollectionById(createdCollection.id);
      await setCurrentCollectionId(createdCollection.id);
      navigate("/");
    } catch (error) {
      console.error("Failed to create collection:", error);
      toast("Error", {
        description: `Failed to create collection: ${error.response.data.error}`,
        action: {
          label: "Close",
          onClick: () => console.log("Error Close"),
        },
      });
    } finally {
      setIsCreating(false);
      setFile(null);
      setTitle("Untitled");
      setLanguage("en-us");
      onClose();
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>Import From Document</DialogTitle>
        <div className="mb-4">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
            className="w-full"
          />
        </div>

        <div className="mb-4">
          <Label htmlFor="document">Document</Label>
          <Input id="document" type="file" onChange={handleFileChange} />
        </div>

        <div className="mb-4">
          <Label htmlFor="language">Language</Label>
          <div className="mb-4 flex">
            <Select
              id="language"
              value={language}
              onValueChange={(value) => setLanguage(value)}
              className="w-full"
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en-us">English - US</SelectItem>
                <SelectItem value="en-gb">English - UK</SelectItem>
                <SelectItem value="en-gb-x-rp">
                  English - Received Pronunciation
                </SelectItem>

                <SelectItem value="fr">French</SelectItem>
                <SelectItem value="fr-be">French - Belgium</SelectItem>
                <SelectItem value="fr-ch">French - Switzerland</SelectItem>

                <SelectItem value="de">German</SelectItem>
                {/* <SelectItem value="ja">Japanese</SelectItem> */}
                <SelectItem value="hi">Hindi</SelectItem>

                <SelectItem value="es">Spanish</SelectItem>
                <SelectItem value="es-419">Spanish - Latin American</SelectItem>

                {/* <SelectItem value="zh">Chinese</SelectItem> */}
                <SelectItem value="it">Italian</SelectItem>
                <SelectItem value="ru">Russian</SelectItem>

                <SelectItem value="pt">Portuguese</SelectItem>
                <SelectItem value="pt-br">Portuguese - Brazil</SelectItem>

                <SelectItem value="ar">Arabic</SelectItem>
                <SelectItem value="ko">Korean</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex justify-center">
          <Button
            className="w-32 dark:bg-green-500 bg-green-500"
            onClick={handleCreateCollection}
            disabled={isCreating}
          >
            {isCreating ? (
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              "Create"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentImportDialog;

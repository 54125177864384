import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  HamburgerMenuIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  AudioLines,
  BarChart2,
  Settings,
  UserRound,
  Library,
  LifeBuoy,
  MessagesSquare,
  Book,
  BookOpenText
} from "lucide-react";
import logo from "@/assets/logo_noborder.png";
import useStore from "@/store";
import { numFreeTrialDays } from "@/constants/freeTrialDays";

function useWindowHeight() {
  const { user } = useStore().user;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return windowHeight;
}

function useMobileQuery() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}

const SidebarItem = ({ icon, label, isOpen, to, onItemClick }) => (
  <div
    className={`flex items-center space-x-2 pl-1 pt-2 pb-2 rounded-md transition duration-200 ease-in-out ${!isOpen && "justify-center"}`}
    onClick={onItemClick} // Add click handler here
  >
    <Link to={to} className="flex items-center space-x-2 w-full">
      <span className="text-xl">{icon}</span>
      {isOpen && <span className="text-sm">{label}</span>}
    </Link>
  </div>
);

const Sidebar = () => {
  const { user } = useStore().user;
  const windowHeight = useWindowHeight();

  const isMobile = useMobileQuery();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef();

  // Function to toggle the sidebar's open state
  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleSidebarItemClick = () => {
    if (isMobile) {
      setIsOpen(false);
    }
  };

  // Function to close the sidebar, can be used when a sidebar item is clicked
  const closeSidebar = () => setIsOpen(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobile &&
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMobile, isOpen]); // Dependencies are isMobile and isOpen

  // Mobile sidebar toggle button
  const mobileMenuButton = (
    <button
      className="fixed top-4 left-4 z-50 text-xl p-2"
      onClick={toggleSidebar}
      style={{ zIndex: 1000 }} // Ensure this is high enough to stay on top of other content
    >
      {isOpen ? <ChevronLeftIcon /> : <HamburgerMenuIcon />}
    </button>
  );

  const getDaysRemaining = (createdAt) => {
    if (createdAt === undefined || createdAt === null) {
      return numFreeTrialDays;
    }

    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - createdDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const remainingDays = numFreeTrialDays - daysDiff;
    return remainingDays >= 0 ? remainingDays : 0;
  };

  return (
    <div>
      {isMobile && !isOpen && mobileMenuButton}
      <div
        ref={sidebarRef}
        style={{
          height: `${windowHeight}px`,
        }}
        className={`${isOpen || !isMobile ? "translate-x-0" : "-translate-x-full"} ${
          isMobile ? "fixed" : "absolute"
        } top-0 left-0 z-40 ${
          !isOpen && !isMobile ? "w-16" : "w-[200px]"
        } p-4 pt-8 transition-transform duration-300 ease-in-out flex flex-col bg-white dark:bg-neutral-900 shadow-sm border border-slate-200 dark:border-neutral-950`}
      >
        <div className="mb-5 flex justify-start items-center">
          <div className="flex items-center space-x-1">
            <img src={logo} alt="Logo" className="w-8 h-8" />
            {isOpen || !isMobile ? (
              <div className="flex items-center space-x-2">
                {isOpen && <span>SpeechCraft</span>}
                {isOpen ? (
                  <ChevronLeftIcon
                    className="bg-white dark:bg-zinc-900 w-5 h-5 border dark:border-zinc-950 rounded-md"
                    style={{ marginLeft: "48px" }}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {/* <ChevronLeftIcon className="w-2 h-2" /> */}
                  </ChevronLeftIcon>
                ) : (
                  <ChevronRightIcon
                    className="bg-white dark:bg-zinc-900 w-5 h-5 border dark:border-zinc-950 rounded-md"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {/* <ChevronRightIcon className="w-2 h-2" /> */}
                  </ChevronRightIcon>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div>
            <SidebarItem
              icon={<AudioLines strokeWidth={1.3} size={22} />}
              label="Learn"
              isOpen={isOpen}
              onItemClick={handleSidebarItemClick}
              to="/"
            />
            <SidebarItem
              icon={<Library strokeWidth={1.3} size={22} />}
              label="Collections"
              isOpen={isOpen}
              onItemClick={handleSidebarItemClick}
              to="/collections"
            />
            <SidebarItem
              icon={<BarChart2 strokeWidth={1.3} size={22} />}
              label="Stats"
              isOpen={isOpen}
              onItemClick={handleSidebarItemClick}
              to="/stats"
            />
          </div>

          <div>
            {isOpen && user && user.subscription_type === "free_trial" && (
              <div
                className="dark:bg-yellow-900 bg-yellow-200 dark:text-yellow-100 text-yellow-900 p-4 mb-4 rounded-lg"
                role="alert"
              >
                <p className="font-bold text-sm mb-1">Free Trial</p>
                <p className="text-sm">
                  {getDaysRemaining(user.created_at) <= 1
                    ? "Your free trial is ending soon."
                    : `You have ${getDaysRemaining(user.created_at)} days remaining in your free trial.`}
                </p>
              </div>
            )}

            <div className="flex items-center h-6 space-x-2 pl-1 pt-4 pb-2 mb-2">
              <a
                href="https://speechcraft.io/docs/intro/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <BookOpenText strokeWidth={1.3} size={22}/>
              </a>

              {isOpen && (
                <div className="flex items-center space-x-2 text-sm">
                  <a
                    href="https://speechcraft.io/docs/intro/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Docs
                  </a>
                </div>
              )}
            </div>

            {/* <div className="flex items-center h-6 space-x-2 pl-1 pt-4 pb-2 mb-2">
              <a
                href="https://speechcraft.featurebase.app/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <MessagesSquare strokeWidth={1.3} size={22} />
              </a>

              {isOpen && (
                <div className="flex items-center space-x-2 text-sm">
                  <a
                    href="https://speechcraft.featurebase.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Forum
                  </a>
                </div>
              )}
            </div> */}

            <SidebarItem
              icon={<Settings strokeWidth={1.3} size={22} />}
              label="Settings"
              isOpen={isOpen}
              onItemClick={handleSidebarItemClick}
              to="/settings"
            />
            <SidebarItem
              icon={<UserRound strokeWidth={1.3} size={22} />}
              label="Profile"
              isOpen={isOpen}
              onItemClick={handleSidebarItemClick}
              to="/profile"
            />

            <div className="flex items-center h-6 space-x-2 pl-1 pt-4 pb-2">
              {!isOpen && (
                <a
                  href="mailto:support@speechcraft.io"
                  style={{ textDecoration: "none" }}
                >
                  <LifeBuoy strokeWidth={1.3} size={22} />
                </a>
              )}
              {isOpen && (
                <div className="flex items-center">
                  <div className="hidden md:block">
                    <LifeBuoy strokeWidth={1.3} size={22} />
                  </div>
                  <a
                    href="mailto:support@speechcraft.io"
                    style={{ textDecoration: "none" }}
                  >
                    <Badge variant="outline">support@speechcraft.io</Badge>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

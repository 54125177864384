const languageMap = {
  // en: "en-US",
  en_us: "en-US",
  en_gb: "en-GB",
  en_gb_x_rp: "en-GB",

  fr_be: "fr-BE",
  fr: "fr-FR",
  fr_ch: "fr-CH",

  de: "de-DE",
  // ja: "ja-JP",
  hi: "hi-IN",

  es: "es-ES",
  es_419: "es-419",
  // zh: "zh-CN",
  it: "it-IT",
  ru: "ru-RU",

  pt: "pt-PT",
  pt_br: "pt-BR",
  
  ar: "ar",
  ko: "ko-KR",
};

export default languageMap;


// const languageMap = {
//   af: "af-ZA",
//   sq: "sq-AL",
//   am: "am-ET",
//   ar: "ar",
//   an: "an-ES",
//   hy: "hy-AM",
//   hyw: "hyw-AM",
//   as: "as-IN",
//   az: "az-AZ",
//   ba: "ba-RU",
//   eu: "eu-ES",
//   be: "be-BY",
//   bn: "bn-BD",
//   bpy: "bpy-IN",
//   bs: "bs-BA",
//   bg: "bg-BG",
//   my: "my-MM",
//   ca: "ca-ES",
//   yue: "yue-HK",
//   hak: "hak-TW",
//   haw: "haw-US",
//   cmn: "cmn-CN",
//   hr: "hr-HR",
//   cs: "cs-CZ",
//   da: "da-DK",
//   nl: "nl-NL",
//   en_us: "en-US",
//   en_gb: "en-GB",
//   en_029: "en-029",
//   en_gb_x_gbclan: "en-GB",
//   en_gb_x_rp: "en-GB",
//   en_gb_scotland: "en-GB",
//   en_gb_x_gbcwmd: "en-GB",
//   eo: "eo",
//   et: "et-EE",
//   fa: "fa-IR",
//   fa_latn: "fa-IR",
//   fi: "fi-FI",
//   fr_be: "fr-BE",
//   fr: "fr-FR",
//   fr_ch: "fr-CH",
//   ga: "ga-IE",
//   gd: "gd-GB",
//   ka: "ka-GE",
//   de: "de-DE",
//   grc: "grc",
//   el: "el-GR",
//   kl: "kl-GL",
//   gn: "gn-PY",
//   gu: "gu-IN",
//   ht: "ht-HT",
//   he: "he-IL",
//   hi: "hi-IN",
//   hu: "hu-HU",
//   is: "is-IS",
//   id: "id-ID",
//   ia: "ia",
//   io: "io",
//   it: "it-IT",
//   ja: "ja-JP",
//   kn: "kn-IN",
//   kok: "kok-IN",
//   ko: "ko-KR",
//   ku: "ku-TR",
//   kk: "kk-KZ",
//   ky: "ky-KG",
//   la: "la",
//   lb: "lb-LU",
//   ltg: "ltg-LV",
//   lv: "lv-LV",
//   lfn: "lfn",
//   lt: "lt-LT",
//   jbo: "jbo",
//   mi: "mi-NZ",
//   mk: "mk-MK",
//   ms: "ms-MY",
//   ml: "ml-IN",
//   mt: "mt-MT",
//   mr: "mr-IN",
//   nci: "nci-MX",
//   ne: "ne-NP",
//   nb: "nb-NO",
//   nog: "nog-RU",
//   or: "or-IN",
//   om: "om-ET",
//   pap: "pap-CW",
//   py: "py",
//   pl: "pl-PL",
//   pt_br: "pt-BR",
//   qdb: "qdb",
//   qu: "qu-PE",
//   quc: "quc-GT",
//   qya: "qya",
//   pt: "pt-PT",
//   pa: "pa-Guru-IN",
//   piqd: "piqd",
//   ro: "ro-RO",
//   ru: "ru-RU",
//   ru_lv: "ru-LV",
//   uk: "uk-UA",
//   sjn: "sjn",
//   sr: "sr-RS",
//   tn: "tn-ZA",
//   sd: "sd-Arab-PK",
//   shn: "shn-MM",
//   si: "si-LK",
//   sk: "sk-SK",
//   sl: "sl-SI",
//   smj: "smj-SE",
//   es: "es-ES",
//   es_419: "es-419",
//   sw: "sw-KE",
//   sv: "sv-SE",
//   ta: "ta-IN",
//   th: "th-TH",
//   tk: "tk-TM",
//   tt: "tt-RU",
//   te: "te-IN",
//   tr: "tr-TR",
//   ug: "ug-CN",
//   ur: "ur-PK",
//   uz: "uz-UZ",
//   vi_vn_x_central: "vi-VN",
//   vi: "vi-VN",
//   vi_vn_x_south: "vi-VN",
//   cy: "cy-GB",
// };

// export default languageMap;

// CommandMenu.js
import React from "react";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut,
} from "@/components/ui/command";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import {
  PlayIcon,
  SpeakerLoudIcon,
  ReloadIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ResetIcon,
} from "@radix-ui/react-icons";
import useStore from "@/store";

const CommandMenu = ({ setOpen, open }) => {
  const { disableShortcuts, setDisableShortcuts } =
    useStore().learningSessionConfig;
  React.useEffect(() => {
    const down = (e) => {
      // Toggle command menu with Ctrl+K or Cmd+K
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }

      // Close command menu with Escape
      if (e.key === "Escape") {
        e.preventDefault();
        setOpen(false);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [setOpen]); // Depend on 'setOpen' to have the latest state


  React.useEffect(() => {
    setDisableShortcuts(open)
  }, [open])

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput placeholder="Type a command or search..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Actions">
          <CommandItem onSelect={() => console.log("Toggle Speak")}>
            <FontAwesomeIcon
              icon={faMicrophone}
              className="icon"
              style={{ fontSize: "18px", marginRight: "8px" }}
            />
            <span>Toggle Speak</span>
            <CommandShortcut>Space</CommandShortcut>
          </CommandItem>
          <CommandItem onSelect={() => console.log("Toggle Listen")}>
            <SpeakerLoudIcon className="mr-2 h-4 w-4" />
            <span>Toggle Listen</span>
            <CommandShortcut>L</CommandShortcut>
          </CommandItem>
          <CommandItem onSelect={() => console.log("Toggle Listen")}>
            <SpeakerLoudIcon className="mr-2 h-4 w-4" />
            <span>Toggle Slow Listen</span>
            <CommandShortcut>K</CommandShortcut>
          </CommandItem>
          <CommandItem onSelect={() => console.log("Reset")}>
            <ResetIcon className="mr-2 h-4 w-4" />
            <span>Reset Speech</span>
            <CommandShortcut>R</CommandShortcut>
          </CommandItem>
          <CommandItem onSelect={() => console.log("Open Collections")}>
            <ResetIcon className="mr-2 h-4 w-4" />
            <span>Open Collections</span>
            <CommandShortcut>C</CommandShortcut>
          </CommandItem>
          <CommandItem onSelect={() => console.log("Previous Content")}>
            <ChevronLeftIcon className="mr-2 h-4 w-4" />
            <span>Previous Content</span>
            <CommandShortcut>←</CommandShortcut>
          </CommandItem>
          <CommandItem onSelect={() => console.log("Next Content")}>
            <ChevronRightIcon className="mr-2 h-4 w-4" />
            <span>Next Content</span>
            <CommandShortcut>→</CommandShortcut>
          </CommandItem>

          <CommandItem onSelect={() => console.log("Repeat Video Segment")}>
            <ReloadIcon className="mr-2 h-4 w-4" />
            <span>Repeat Video Segment</span>
            <CommandShortcut>↑</CommandShortcut>
          </CommandItem>

          <CommandItem onSelect={() => console.log("Toggle Video Play/Pause")}>
            <PlayIcon className="mr-2 h-4 w-4" />{" "}
            {/* Adjust the icon as needed */}
            <span>Toggle Video Play/Pause</span>
            <CommandShortcut>↓</CommandShortcut>
          </CommandItem>
        </CommandGroup>
        {/* <CommandSeparator />
            <CommandGroup heading="Go to">
              <CommandItem>
                <PersonIcon className="mr-2 h-4 w-4" />
                <span>Profile</span>
                <CommandShortcut>P</CommandShortcut>
              </CommandItem>
              <CommandItem>
                <GearIcon className="mr-2 h-4 w-4" />
                <span>Settings</span>
                <CommandShortcut>S</CommandShortcut>
              </CommandItem>
            </CommandGroup> */}
      </CommandList>
    </CommandDialog>
  );
};

export default CommandMenu;

// SessionResults.js
import React from "react";
import DonutChart from "@/components/LearningSession/DonutChart";

const SessionResults = ({
  wordsPerMinute,
  accuracy,
  elapsedTime,
  repeatable,
  fullRepeatCount,
}) => {
  console.log(
    "session results",
    wordsPerMinute,
    accuracy,
    elapsedTime,
    repeatable,
    fullRepeatCount
  );
  if (repeatable) {
    return (
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-center">
          <div className="text-2xl">{fullRepeatCount}</div>
          <div className="text-sm opacity-60">Repeats</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-2xl">
            {Math.floor(wordsPerMinute * fullRepeatCount)}
          </div>
          <div className="text-sm opacity-60">Repeats per minute</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center space-x-10">
        <div className="flex flex-col">
          <div className="text-sm opacity-60">Words / min</div>
          <div className="text-[24px]">{wordsPerMinute.toFixed(0)}</div>
        </div>
        <div>
          <div className="flex flex-col items-center mt-2 mb-2">
            <DonutChart percentage={accuracy.toFixed(0)} size={90} />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-sm opacity-60">Time Elapsed</div>
          <div className="text-[24px]">{elapsedTime.toFixed(0)}s</div>
        </div>
      </div>
    );
  }
};

export default SessionResults;

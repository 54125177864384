import he from 'he';
import numWords from "num-words";
import wordsToNumbers from "words-to-numbers";
import TooltipWrapper from "@/components/TooltipWrapper";
import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import WordPracticeDialog from "@/components/LearningSession/TextWall/WordPracticeDialog";
import { Languages } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faVolumeDown,
  faLanguage,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import "./TextWall.css";
import TranslateService from "@/services/TranslateService";
import languageMap from "@/constants/languageMap";

import useStore from "@/store";

function debounce(func, delay) {
  let timeoutId;
  const cancel = () => {
    clearTimeout(timeoutId);
  };
  const debouncedFunc = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
  debouncedFunc.cancel = cancel;
  return debouncedFunc;
}

const speakWord = debounce((word, language, rate = 1) => {
  window.speechSynthesis.cancel();

  var msg = new SpeechSynthesisUtterance(word);
  const convertedLanguageKey = language.replace(/-/g, "_");
  msg.lang = languageMap[convertedLanguageKey] || "en-US";
  msg.rate = rate;

  window.speechSynthesis.speak(msg);
}, 300);

const Space = () => (
  <span style={{ display: "inline-block", width: "0.5em" }}>&nbsp;</span>
);

const Word = ({
  text,
  wrongWord,
  style,
  phonemes,
  transcribedPhonemes,
  phonemeMatches,
  accuracy,
  onWordClick,
}) => {
  const { settings } = useStore().settings;
  const { currentCollection } = useStore().collections;
  const { triggerPauseVideo, triggerContinueVideo } =
    useStore().learningSessionConfig;
  const language = currentCollection.language || "en";
  const [open, setOpen] = useState(false);

  // Use the values from the settings object, with defaults as fallbacks
  const nativeLanguage = settings?.native_language || "en";

  // Function to strip special characters but preserve hyphens

  const [translation, setTranslation] = useState("");
  const [isTranslating, setIsTranslating] = useState(false);

  useEffect(() => {
    return () => {
      speakWord.cancel();
    };
  }, []);

  const translateText = async () => {
    setIsTranslating(true);
    try {
      const cleanedText = stripSpecialChars(text.replace(/\n/g, ""));
      const result = await TranslateService.translateText(
        cleanedText,
        language,
        nativeLanguage
      );
      setTranslation(result.translated_text);
    } catch (error) {
      console.error("Error translating text:", error);
      // Handle the error appropriately
    } finally {
      setIsTranslating(false);
    }
  };
  const stripSpecialChars = (str) => {
    // This regex will remove specific punctuation characters: . , " ; : ! ? ( ) [ ]
    // only at the beginning or end of the word, excluding apostrophes
    return str.replace(/^[.,"!;:?()[\]-]+|[.,"!;:?()[\]-]+$/g, "");
  };

  const onOpenChange = (isOpen) => {
    if (isOpen) {
      triggerPauseVideo();
    }
    // else {
    //   triggerContinueVideo();
    // }
  };

  return (
    <React.Fragment>
      {text.includes("\n") && (
        <>
          <br />
          <br />
        </>
      )}
      <span style={style} className="text-lg md:text-xl">
        <Popover
          onOpenChange={() => {
            onOpenChange(!open);
            onWordClick();
            if (!translation) {
              translateText();
            }
          }}
        >
          <PopoverTrigger>{text.replace(/\n/g, "")}</PopoverTrigger>

          <PopoverContent>
            <div
              className="text-lg"
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative", // For absolute positioning of the mic icon
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span className="text-xl">
                  {stripSpecialChars(text.replace("\n", ""))}
                  <FontAwesomeIcon
                    icon={faVolumeUp}
                    onClick={() =>
                      speakWord(
                        text,
                        language
                      )
                    }
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      opacity: 0.7,
                      fontSize: "0.8em",
                    }}
                  />

                  <FontAwesomeIcon
                    icon={faVolumeDown}
                    onClick={() =>
                      speakWord(
                        text,
                        language,
                        0.1
                      )
                    }
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      opacity: 0.7,
                      fontSize: "0.8em",
                    }}
                  />
                </span>
                <div
                  onClick={() => setOpen(true)}
                  className="cursor-pointer dark:bg-zinc-800 bg-gray-200 border border-gray-200 dark:border-zinc-950 rounded-md pl-2 pr-2 inline-block"
                >
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    className="ml-auto"
                    style={{ opacity: 0.9, fontSize: "0.8em" }}
                  />
                </div>
              </div>
              {language.split('-')[0] !== nativeLanguage && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "6px",
                    height: "20px", // Fixed height to prevent layout shift
                    overflow: "hidden", // Prevents overflow, adjust as needed
                  }}
                >
                  <Languages
                    onClick={translateText}
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      width: "16px",
                      opacity: 0.7,
                    }}
                  />
                  {/* <FontAwesomeIcon
                    icon={faLanguage}
                    onClick={translateText}
                    style={{ cursor: "pointer", marginRight: "5px" }}
                  /> */}
                  {isTranslating ? (
                    <div className="loader w-3 h-3 border-2 dark:border-black  border-t-green-500 rounded-full"></div>
                  ) : (
                    <span className="text text-[16px]">{he.decode(translation)}</span>
                  )}
                </div>
              )}
            </div>
            <br />
            {wrongWord && (
              <span style={{ color: "#ef4444" }}>
                {stripSpecialChars(wrongWord)}
              </span>
            )}
            {phonemeMatches && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {phonemeMatches.map((phoneme, index) => {
                  // Extracting key (phoneme letter) and value ('X' or 'M') from each phoneme object
                  const [phonemeLetter, status] = Object.entries(phoneme)[0];
                  const color = status === "X" ? "#ff0000" : "#4fb701"; // Red for 'X', Green for 'M'
                  return (
                    <div key={index} style={{ color, marginRight: "5px" }}>
                      {phonemeLetter}
                    </div>
                  );
                })}
              </div>
            )}
            {accuracy && (
              <div className="flex items-center space-x-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className="h-2.5 rounded-full"
                    style={{
                      width: `${accuracy}%`,
                      backgroundColor: accuracyToColor(accuracy),
                    }}
                  ></div>
                </div>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  {accuracy.toFixed(0)}%
                </span>
              </div>
            )}
          </PopoverContent>
        </Popover>
      </span>
      <WordPracticeDialog
        open={open}
        setOpen={setOpen}
        word={stripSpecialChars(text)}
        language={language}
      />
      {/* <Space /> */}
    </React.Fragment>
  );
};

const Spacer = () => (
  <span style={{ display: "inline-block", width: "8px" }}></span>
); // Adjust width as needed

const Sentence = ({ sentence, isActive, words }) => {
  const {
    user,
    onboardingEnabled,
    onboardingStages,
    setOnboardingStage,
    setOnboardingEnabled,
  } = useStore().user;
  const [showWordClickOnboarding, setShowWordClickOnboarding] = useState(false);
  const sentenceStyle = isActive ? {} : {};
  const { currentCollection } = useStore().collections;
  const language = currentCollection.language || "en";

  useEffect(() => {
    if (
      onboardingEnabled &&
      onboardingStages.speakButtonClicked &&
      !onboardingStages.wordClicked &&
      words[0].phonemes
    ) {
      setShowWordClickOnboarding(true);
    } else {
      setShowWordClickOnboarding(false);
    }
  }, [onboardingEnabled, onboardingStages, words]);

  const handleWordClick = () => {
    if (showWordClickOnboarding) {
      setOnboardingStage("wordClicked", true);
      setOnboardingEnabled(false);
    }
  };

  return (
    <TooltipWrapper
      tooltipText={"Speak"}
      onboarding={showWordClickOnboarding}
      onboardingMessage="Click on any word to listen, translate or practice separately."
    >
      <span key={sentence} style={sentenceStyle}>
        {language === "ar" ? (
          words.map((word, index) => (
            <React.Fragment key={index}>
              {index >= 0 && <Spacer />}
              <Word
                text={word.text}
                style={word.style}
                wrongWord={word.wrongWord}
                phonemes={word.phonemes}
                transcribedPhonemes={word.transcribed_phonemes}
                phonemeMatches={word.phoneme_matches}
                accuracy={word.accuracy}
                onWordClick={handleWordClick}
              />
            </React.Fragment>
          )).reverse()
        ) : (
          words.map((word, index) => (
            <React.Fragment key={index}>
              <Word
                text={word.text}
                style={word.style}
                wrongWord={word.wrongWord}
                phonemes={word.phonemes}
                transcribedPhonemes={word.transcribed_phonemes}
                phonemeMatches={word.phoneme_matches}
                accuracy={word.accuracy}
                onWordClick={handleWordClick}
              />
              {index < words.length - 1 && <Spacer />}
            </React.Fragment>
          ))
        )}
      </span>
    </TooltipWrapper>
  );
};
function accuracyToColor(accuracy) {
  if (accuracy >= 0 && accuracy < 17) {
    return "#dc2626"; // Red
  } else if (accuracy >= 17 && accuracy < 34) {
    return "#ea580c"; // OrangeRed
  } else if (accuracy >= 34 && accuracy < 51) {
    return "#d97706"; // DarkOrange
  } else if (accuracy >= 51 && accuracy < 68) {
    return "#ca8a04"; // Gold
  } else if (accuracy >= 68 && accuracy < 85) {
    return "#65a30d"; // YellowGreen
  } else {
    return "#16a34a"; // Green
  }
}

function processPhonemes(displayedText, phonemes) {
  displayedText = displayedText.replace(/\n/g, " ");
  const displayedSentences = displayedText.split(/\n+/);
  let elements = [];

  if (phonemes.length === 0) {
    displayedSentences.forEach((sentence) => {
      const words = sentence
        .split(" ")
        .filter((word) => word.trim() !== "")
        .map((word, index) => {
          return { text: word, style: {} };
        });

      elements.push(
        <Sentence
          key={sentence}
          sentence={sentence}
          isActive={true}
          words={words}
        />
      );
    });
  } else {
    const updatedPhonemes = phonemes.map((phoneme) => ({
      ...phoneme,
      style: {
        color: accuracyToColor(phoneme.accuracy),
      },
    }));

    elements.push(
      <Sentence
        key={updatedPhonemes[0].word}
        sentence={updatedPhonemes[0].word}
        isActive={true}
        words={updatedPhonemes}
      />
    );
  }

  return elements;
}

function processTexts(
  resultText,
  interimResultText,
  displayedText,
  activeSentence
) {
  const resultWords = (resultText + " " + interimResultText)
    .split(" ")
    .filter((word) => word.trim() !== "");

  resultWords.forEach((word, index) => {
    // Convert numbers to words for comparison
    if (!isNaN(word)) {
      // If the word is a numeric value
      resultWords[index] = numWords(parseInt(word, 10));
    } else {
      // Attempt to convert word to number and back to word to normalize words like "forty-two" and "42"
      const possibleNumber = wordsToNumbers(word);
      if (!isNaN(possibleNumber)) {
        resultWords[index] = numWords(possibleNumber);
      }
    }
  });

  displayedText = displayedText.replace(/\n/g, " ");

  const displayedSentences = displayedText.split(/\n+/);

  let elements = [];
  let currentWordIndex = 0;

  displayedSentences.forEach((sentence) => {
    const isActive = sentence.trim() === activeSentence.trim();
    const sentenceWords = sentence
      .split(" ")
      .filter((word) => word.trim() !== "");

    const words = sentenceWords.map((word, index) => {
      const cleanWord = word.toLowerCase().replace(/[^\p{L}\p{N}\s]|_/gu, "");
      const expectedWord = resultWords[currentWordIndex]
        ? resultWords[currentWordIndex]
            .toLowerCase()
            .replace(/[^\p{L}\p{N}\s]|_/gu, "")
        : null;

      let wrongWord = null;
      // Logic to determine if the word is wrong and set wrongWord accordingly
      if (expectedWord && cleanWord !== expectedWord) {
        wrongWord = expectedWord; // This assumes you have a way to identify the wrong word
      }

      let style = {};
      if (currentWordIndex < resultWords.length) {
        style = {
          color: cleanWord === expectedWord ? "#4fb701" : "#ef4444",
        };
      }

      currentWordIndex++;
      return { text: word, wrongWord, style };
    });

    elements.push(
      <Sentence
        key={sentence}
        sentence={sentence}
        isActive={isActive}
        words={words}
      />
    );
  });

  return elements;
}

const TextWall = ({
  text,
  transcribedSpeechText,
  interimTranscribedSpeechText,
  activeSentence,
  endOfTextReached,
  phonemes,
  mode = "realtime",
}) => {
  const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });

  const textWallRef = useRef(null);
  const tooltipRef = useRef(null);

  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(false);
  const [hasEndOfTextBeenReached, setHasEndOfTextBeenReached] = useState(false);

  useEffect(() => {
    if (hasEndOfTextBeenReached || transcribedSpeechText.length === 0) {
      setHasEndOfTextBeenReached(false);
    }
  }, [text, transcribedSpeechText]);

  useEffect(() => {
    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);

  const checkScrollability = () => {
    if (textWallRef.current) {
      const element = textWallRef.current;
      setCanScrollUp(element.scrollTop > 0);
      setCanScrollDown(
        element.scrollTop < element.scrollHeight - element.clientHeight
      );
    }
  };

  useEffect(() => {
    const element = textWallRef.current;
    if (element) {
      checkScrollability();
      element.addEventListener("scroll", checkScrollability);

      return () => element.removeEventListener("scroll", checkScrollability);
    }
  }, []);

  useEffect(() => {
    if (!hasEndOfTextBeenReached) {
      // Normalize and prepare the texts for comparison
      const normalizeText = (text) =>
        text
          .toLowerCase()
          .replace(/[^\p{L}\p{N}\s]|_/gu, "")
          .split(/\s+/)
          .filter(Boolean);
      let transcribedWords = normalizeText(
        transcribedSpeechText + " " + interimTranscribedSpeechText
      );

      transcribedWords.forEach((word, index) => {
        // Convert numbers to words for comparison
        if (!isNaN(word)) {
          // If the word is a numeric value
          transcribedWords[index] = numWords(parseInt(word, 10));
        } else {
          // Attempt to convert word to number and back to word to normalize words like "forty-two" and "42"
          const possibleNumber = wordsToNumbers(word);
          if (!isNaN(possibleNumber)) {
            transcribedWords[index] = numWords(possibleNumber);
          }
        }
      });

      const originalWords = normalizeText(text);
      let wrongWords = []; // Initialize an array to hold the wrong words
      let correctCount = 0;

      originalWords.forEach((word, index) => {
        if (transcribedWords[index] === word) {
          correctCount++;
        } else if (transcribedWords[index] !== undefined) {
          // Ensure comparison only when there's a transcribed word
          wrongWords.push(word); // Add the wrong word to the array
        }
      });

      const accuracy =
        originalWords.length > 0
          ? (correctCount / originalWords.length) * 100
          : 0;
      if (
        transcribedWords.length >= originalWords.length &&
        interimTranscribedSpeechText.trim() === ""
      ) {
        endOfTextReached(accuracy, originalWords, transcribedWords, wrongWords);
        setHasEndOfTextBeenReached(true);
      }
    }
  }, [
    transcribedSpeechText,
    interimTranscribedSpeechText,
    text,
    endOfTextReached,
    hasEndOfTextBeenReached,
  ]);

  // Custom styles to encourage scrollbar visibility
  const textWallStyles = {
    overflowY: "scroll", // Encourage vertical scrollbar
    maxHeight: "100%", // Adjust as needed to fit your layout
    paddingRight: "2px", // Prevent text clipping by scrollbar
    // Additional styles for your text wall container
  };

  // Scrollbar styles for WebKit browsers
  const scrollBarStyles = `
  ::-webkit-scrollbar {
    width: 12px; // Adjust the width of the scrollbar as needed
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2); // Adjust the color of the scroll thumb as needed
    border-radius: 6px; // Rounded corners for the scroll thumb
  }
  ::-webkit-scrollbar-track {
    background-color: transparent; // Ensure the track background doesn't introduce unwanted visual elements
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent; /* or your desired corner color */
  }
  
`;

  return (
    <div
      ref={textWallRef}
      style={textWallStyles}
      // style={{
      //   boxShadow: `${
      //     canScrollUp ? "inset 0 15px 10px -10px rgba(0, 0, 0, 0.15)" : ""
      //   }${canScrollUp && canScrollDown ? "," : ""}${
      //     canScrollDown ? "inset 0 -15px 10px -10px rgba(0, 0, 0, 0.15)" : ""
      //   }`,
      // }}
    >
      <style>{scrollBarStyles}</style>

      <p className="first-step leading-10 whitespace-pre-wrap">
        {mode === "realtime"
          ? processTexts(
              transcribedSpeechText,
              interimTranscribedSpeechText,
              text,
              activeSentence
            )
          : mode === "phoneme"
            ? processPhonemes(text, phonemes)
            : null}
      </p>
    </div>
  );
};

export default TextWall;

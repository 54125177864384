import React from "react";
import { Bars } from "react-loader-spinner";

const InnerContainerLoader = ({ offset = 12 }) => {
  return (
    <div
      className="dark:very-dark-grey w-full flex justify-center items-center"
      style={{ height: "calc(100vh - var(--dynamic-mt, 0px))" }}
    >
      <Bars
        height="20"
        width="20"
        color="#22c55e"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <style>
        {`
      @media (min-width: 768px) { /* Corresponds to Tailwind's 'md' breakpoint */
        :root {
          --dynamic-mt: ${offset}rem; /* 24 units for 'md' and above */
        }
      }
      @media (max-width: 767px) {
        :root {
          --dynamic-mt: ${offset / 2}rem; /* 12 units for below 'md' */
        }
      }
    `}
      </style>
    </div>
  );
};

export default InnerContainerLoader;

// src/services/UserService.js
import { requestWithAuth } from "@/utils/authUtils";

const UserService = {
    async upsertUser(userData) {
        try {
            const url = '/users/upsert';
            const method = 'POST';
            const body = { ...userData };
            return await requestWithAuth(url, method, body);
        } catch (error) {
            console.error("Error in upserting user:", error);
            throw error;
        }
    },

    async getUserSettings() {
        try {
            const url = '/user-settings';
            const method = 'GET';
            return await requestWithAuth(url, method);
        } catch (error) {
            console.error("Error fetching user settings:", error);
            throw error;
        }
    },

    async updateUserSettings(settingsData) {
        try {
            const url = '/user-settings/update';
            const method = 'POST';
            const body = { ...settingsData };
            return await requestWithAuth(url, method, body);
        } catch (error) {
            console.error("Error updating user settings:", error);
            throw error;
        }
    },
    async updateCurrentCollection(currentCollectionId) {
        try {
            const url = '/user/current-collection'; // Ensure this matches the route defined in your Flask app
            const method = 'POST';
            const body = { current_collection_id: currentCollectionId }; // Match this key with what your backend expects
            return await requestWithAuth(url, method, body);
        } catch (error) {
            console.error("Error updating current collection:", error);
            throw error;
        }
    }
};

export default UserService;

import React, { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Button } from "@/components/ui/button";
import { Heart, Library } from "lucide-react"; // Import your icons
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
// import { Label } from "@/components/ui/label";¯
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import posthog from "posthog-js";

const Profile = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        posthog.capture("user_logged_out");
        // Handle successful signout
      })
      .catch((error) => {
        // Handle signout errors
      });
  };

  function formatDate(timestamp) {
    const date = new Date(parseInt(timestamp));
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  useEffect(() => {
    posthog.capture("profile_page_viewed");
  }, []);

  console.log("user", user);
  if (!user) {
    return null;
  }

  return (
    <div className="flex justify-center items-center md:mt-0 mt-24">
      <div className="dark:bg-dark-grey bg-zinc-50 p-4 rounded-lg dark:border dark:border-very-dark-grey max-w-xl w-full sm:w-1/3 border border-slate-100 dark:border-zinc-800 shadow-sm">
        <h1 className="text-xl mb-4">Profile</h1>
        {/* Joined Date and Collections */}
        <div className="flex flex-col text-sm p-1 space-y-2">
          {/* Joined Date */}
          <div className="text-md text-white-400">
            Joined in {formatDate(user.metadata.createdAt)}
          </div>

          {/* Collections with Number */}
          {/* <div className="flex items-center space-x-2">
            <Library strokeWidth={1.3} size={22} className="text-green-500" />
            <span>112</span>
          </div> */}

          {/* Likes with Number */}
          {/* <div className="flex items-center space-x-2">
            <Heart strokeWidth={1.3} size={22} className="text-red-500" />
            <span>2k</span> 
          </div> */}
        </div>
        <br></br>

        <div className="sm:flex mt-2 mb-6">
          <div className="ml-4 mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
            {user.photoURL ? (
              <img
                className="h-12 w-12 rounded-full border border-gray-300 bg-white"
                src={user.photoURL}
                alt="Profile"
              />
            ) : (
              <svg
                className="h-16 w-16 border border-gray-300 bg-white text-gray-300"
                preserveAspectRatio="none"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 200 200"
                aria-hidden="true"
              >
                <path
                  vector-effect="non-scaling-stroke"
                  strokeWidth="1"
                  d="M0 0l200 200M0 200L200 0"
                />
              </svg>
            )}
          </div>
          <div>
            <h4 className="text-lg font-bold">{user.displayName}</h4>
            <CardDescription>
              <p className="mt-1">{user.email}</p>
            </CardDescription>
          </div>
          <br></br>
        </div>

        <div className="flex justify-center p-5">
          <Button variant="destructive" onClick={handleLogout}>
            Log out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;

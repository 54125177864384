import React, { useState, useEffect } from "react";
import useStore from "@/store";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import CollectionService from "@/services/CollectionService";
import { toast } from "sonner";
import posthog from "posthog-js";

const CollectionItem = ({ title, onPractice, onEdit, onDelete, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex justify-between items-center shadow-sm rounded-lg m-1 cursor-pointer dark:bg-dark-grey p-4 dark:border-none border border-gray-200"
    >
      <div className="text-lg">{title}</div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="p-2 rounded-full hover:bg-gray-200"
          >
            <DotsHorizontalIcon className="h-6 w-6" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48">
          <DropdownMenuItem onClick={onPractice}>Practice</DropdownMenuItem>
          <DropdownMenuItem onClick={onEdit}>Edit</DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const Collections = ({
  collections: initialCollections,
  onCollectionClick,
}) => {
  const navigate = useNavigate();
  const { user, setCurrentCollectionId } = useStore().user;
  const {
    fetchCollections,
    setCurrentCollectionById,
    collectionContents,
    currentCollection,
    currentContent,
    setCurrentContent,
  } = useStore().collections;

  const {
    fetchActiveReadingPosition,
    readingPosition,
    updateReadingPosition,
    updatePositionInState,
    upsertReadingPosition,
  } = useStore().learningSessionConfig;

  const [collections, setCollections] = useState(initialCollections);

  useEffect(() => {
    setCollections(initialCollections);
  }, [initialCollections]);

  const handlePractice = async (collectionId) => {
    try {
      await setCurrentCollectionId(collectionId);
      navigate("/");
    } catch (error) {
      console.error("Failed to set current collection ID:", error);
    }
  };

  const handleDelete = async (collectionId) => {
    try {
      await CollectionService.deleteCollection(collectionId);
      toast("Collection deleted", {
        description: "The collection has been successfully deleted!",
        action: {
          label: "Close",
          onClick: () => console.log("Undo action clicked"),
        },
      });
      posthog.capture("collection_deleted");

      const updatedCollections = collections.filter(
        (collection) => collection.id !== collectionId
      );
      console.log("the updated collections", updatedCollections);
      setCollections(updatedCollections);
      console.log("user current collection id", user.current_collection_id);

      if (user.current_collection_id === collectionId) {
        const newCurrentCollectionId =
          updatedCollections.length > 0 ? updatedCollections[0].id : null;
        setCurrentCollectionId(newCurrentCollectionId);
        console.log("the new current collection id", newCurrentCollectionId);
      }
    } catch (error) {
      console.error("Failed to delete collection:", error);
    }
  };

  return (
    <div>
      {collections.map((collection) => (
        <CollectionItem
          key={collection.id}
          title={collection.title}
          onPractice={() => handlePractice(collection.id)}
          onEdit={() => console.log("Edit", collection.title)}
          onDelete={() => handleDelete(collection.id)}
          onClick={() => onCollectionClick(collection.id)}
        />
      ))}
    </div>
  );
};

export default Collections;
// ControlButtons.js
import React, { useEffect, useState, useRef } from "react";
import TooltipWrapper from "@/components/TooltipWrapper";
import { Button } from "@/components/ui/button";
import { useTour } from "@reactour/tour";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ReloadIcon,
  PlayIcon,
  PauseIcon,
  ResetIcon,
  SpeakerLoudIcon,
} from "@radix-ui/react-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faStop } from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import useStore from "@/store";
import "./ControlButtons.css";

const ControlButtons = ({
  mode,
  onPrev,
  onNext,
  endOfCollectionReached,
  onReset,
  onStartStopRecording,
  onStartStopListening,
  isRecording,
  isListening,
  isPredicting,
  processing,
  handleReplay,
  isVideoPlaying,
  setIsVideoPlaying,
}) => {
  const { currentCollection } = useStore().collections;
  const { user, onboardingEnabled, onboardingStages, setOnboardingStage } =
    useStore().user;
  const { isOnboarding, setIsOnboarding } = useStore().onboarding;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOnboardingUser, setIsOnboardingUser] = useState(true);
  const { wordPracticeDialogOpened, triggerPauseVideo, disableShortcuts } =
    useStore().learningSessionConfig;

  const { setIsOpen } = useTour();

  useEffect(() => {
    if (isOnboarding) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isOnboarding, setIsOpen]);

  const handleStartStopRecording = () => {
    triggerPauseVideo();
    if (!processing && !isPredicting) {
      if (user.subscription_type === "free" && mode === "phoneme") {
        setIsDialogOpen(true);
      } else {
        onStartStopRecording();
      }
    }
  };

  const handleUpgrade = () => {
    // Handle upgrade logic here
    console.log("Upgrade to Plus");
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (wordPracticeDialogOpened || disableShortcuts) {
        return;
      }
      // Check if any of the modifier keys are pressed
      if (e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) {
        // Do not interfere with system/browser shortcuts
        return;
      }

      switch (e.key) {
        case " ":
          if (!e.repeat) {
            triggerPauseVideo();
            e.preventDefault();
            onStartStopRecording();
          }
          break;
        case "k":
        case "K":
          e.preventDefault();
          triggerPauseVideo();
          onStartStopListening(0.1);
          break;
        case "l":
        case "L":
          e.preventDefault();
          triggerPauseVideo();
          onStartStopListening(1);
          break;
        case "r":
        case "R":
          e.preventDefault();
          onReset();
          break;
        case "ArrowRight":
          e.preventDefault();
          onNext();
          break;
        case "ArrowLeft":
          e.preventDefault();
          onPrev();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onPrev, onNext, onReset, onStartStopRecording, onStartStopListening]);

  const onReplayClick = () => {
    handleReplay();
  };

  return (
    <div className="flex items-center">
      <TooltipWrapper tooltipText={"Go to previous content"}>
        <Button
          variant="outline"
          size="icon"
          onClick={onPrev}
          className="md:mr-6 mr-3"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </Button>
      </TooltipWrapper>

      {currentCollection.content_type === "youtube" ? (
        <>
          <TooltipWrapper tooltipText={"Listen to the content"}>
            <Button
              variant="outline"
              className="px-4 rounded-r-none"
              onClick={() => {
                triggerPauseVideo();
                onStartStopListening(1);
              }}
            >
              {isListening ? (
                <>
                  <PauseIcon className="h-4 w-4" />
                  <span className="ml-1 hidden sm:inline">Pause</span>
                </>
              ) : (
                <>
                  <SpeakerLoudIcon className="h-4 w-4" />
                  <span className="ml-1 hidden sm:inline">Listen</span>
                </>
              )}
            </Button>
          </TooltipWrapper>
          <TooltipWrapper tooltipText={"Reset the speaking session"}>
            <Button
              className="px-4  rounded-l-none"
              variant="outline"
              onClick={onReset}
            >
              <ResetIcon className="h-4 w-4" />
              <span className="ml-1 hidden sm:inline">Reset</span>
            </Button>
          </TooltipWrapper>
        </>
      ) : (
        <TooltipWrapper tooltipText={"Reset the speaking session"}>
          <Button className="px-4 " variant="outline" onClick={onReset}>
            <ResetIcon className="h-4 w-4" />
            <span className="ml-1 hidden sm:inline">Reset</span>
          </Button>
        </TooltipWrapper>
      )}

      <TooltipWrapper
        tooltipText={"Speak"}
        onboarding={onboardingEnabled && !onboardingStages.speakButtonClicked}
        onboardingMessage="Please click this button to start speaking the text"
      >
        <button
          className={`rounded-full flex items-center justify-center transition duration-300 ease-in-out w-[50px] h-[50px] md:mx-6 mx-3 relative ${
            isRecording || processing || isPredicting
              ? "bg-red-500 hover:bg-red-700 pulsating-halo"
              : "bg-slate-200 hover:bg-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700"
          } ${isPredicting ? "cursor-not-allowed" : "cursor-pointer"}`}
          disabled={processing || isPredicting}
          onClick={() => {
            if (onboardingEnabled && !onboardingStages.speakButtonClicked) {
              setOnboardingStage("speakButtonClicked", true);
            }
            if (!isPredicting) {
              handleStartStopRecording();
            }
          }}
        >
          {isPredicting ? (
            <div
              id="speakingButton"
              className="flex justify-center items-center w-full h-full"
            >
              <svg
                className="animate-spin h-8 w-8 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : isRecording ? (
            <FontAwesomeIcon
              icon={faStop}
              className="icon text-white"
              style={{ fontSize: "24px" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faMicrophone}
              className="icon"
              style={{ fontSize: "18px" }}
            />
          )}
        </button>
      </TooltipWrapper>

      {currentCollection.content_type === "youtube" ? (
        <>
          <TooltipWrapper tooltipText={"Toggle video playback"}>
            <Button
              variant="outline"
              className="px-4 rounded-r-none"
              onClick={() => setIsVideoPlaying(!isVideoPlaying)}
            >
              {isVideoPlaying ? (
                <>
                  <PauseIcon className="h-4 w-4" />
                  <span className="ml-1 hidden sm:inline">Pause</span>
                </>
              ) : (
                <>
                  <PlayIcon className="h-4 w-4" />
                  <span
                    className="ml-1 hidden sm:inline"
                    style={{ width: "37px" }}
                  >
                    Play
                  </span>
                </>
              )}
            </Button>
          </TooltipWrapper>
          <TooltipWrapper tooltipText={"Replay video segment"}>
            <Button
              variant="outline"
              className="px-4  rounded-l-none"
              onClick={onReplayClick}
            >
              <ReloadIcon className="h-4 w-4" />
              <span className="ml-1 hidden sm:inline">Replay</span>
            </Button>
          </TooltipWrapper>
        </>
      ) : (
        <TooltipWrapper tooltipText={"Replay video segment"}>
          <Button
            variant="outline"
            className="px-4 "
            onClick={() => onStartStopListening(1)}
          >
            {isListening ? (
              <>
                <PauseIcon className="h-4 w-4" />
                <span className="ml-1 hidden sm:inline">Pause</span>
              </>
            ) : (
              <>
                <SpeakerLoudIcon className="h-4 w-4" />
                <span className="ml-1 hidden sm:inline">Listen</span>
              </>
            )}
          </Button>
        </TooltipWrapper>
      )}

      <TooltipWrapper tooltipText={"Go to next content"}>
        <Button
          variant="outline"
          size="icon"
          onClick={onNext}
          disabled={endOfCollectionReached}
          className="md:ml-6 ml-3"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </Button>
      </TooltipWrapper>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Feature Unavailable</DialogTitle>
            <DialogDescription>
            This feature is not available in the free tier. Please upgrade to the Plus plan, or use the real-time model (only available in Chrome on desktop).
            </DialogDescription>
          </DialogHeader>
          {/* <DialogFooter>
            <Button onClick={handleUpgrade}>Upgrade to Plus</Button>
          </DialogFooter> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ControlButtons;

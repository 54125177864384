// CreateCollectionDialog.js
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTitle, DialogContent } from "@/components/ui/dialog";
import { ListBulletIcon, VideoIcon, UploadIcon } from "@radix-ui/react-icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useNavigate } from "react-router-dom";
import YouTubeImportDialog from "@/components/Collections/YouTubeImportDialog";
import DocumentImportDialog from "@/components/Collections/DocumentImportDialog";

const CreateCollectionDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [isYouTubeImportOpen, setIsYouTubeImportOpen] = useState(false);
  const [isDocumentImportOpen, setIsDocumentImportOpen] = useState(false);

  const handleManualCollection = () => {
    navigate("/collections/new");
    onClose();
  };

  const openYouTubeImportDialog = () => {
    setIsYouTubeImportOpen(true);
  };

  const closeYouTubeImportDialog = () => {
    setIsYouTubeImportOpen(false);
  };

  const openDocumentImportDialog = () => {
    setIsDocumentImportOpen(true);
  };

  const closeDocumentImportDialog = () => {
    setIsDocumentImportOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>Create a new collection</DialogTitle>
        <Alert className="cursor-pointer" onClick={handleManualCollection}>
          <ListBulletIcon className="h-4 w-4" />
          <AlertTitle>Normal Collection</AlertTitle>
          <AlertDescription>Manually create a collection.</AlertDescription>
        </Alert>
        <Alert className="cursor-pointer" onClick={openYouTubeImportDialog}>
          <VideoIcon className="h-4 w-4" />
          <AlertTitle>Import From YouTube</AlertTitle>
          <AlertDescription>
            Import subtitles as cards from a YouTube video.
          </AlertDescription>
        </Alert>
        <Alert className="cursor-pointer" onClick={openDocumentImportDialog}>
          <UploadIcon className="h-4 w-4" />
          <AlertTitle>Import From Document</AlertTitle>
          <AlertDescription>
            Import txt, rtf, pdf, md, doc or docx files.
          </AlertDescription>
        </Alert>
      </DialogContent>
      <YouTubeImportDialog
        open={isYouTubeImportOpen}
        onClose={closeYouTubeImportDialog}
      />
      <DocumentImportDialog
        open={isDocumentImportOpen}
        onClose={closeDocumentImportDialog}
      />
    </Dialog>
  );
};

export default CreateCollectionDialog;

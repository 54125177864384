import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App2";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "@/components/ui/sonner";
import posthog from "posthog-js";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";

// Function to check if the app is running on localhost
const isLocalhost = () => {
  return (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname.startsWith("192.168") ||
    window.location.hostname.startsWith("10.")
  );
};

// Initialize PostHog only if not running on localhost
if (!isLocalhost()) {
  posthog.init("phc_3qJHikLP2lltkz1SeicbXjRJFHZabmn0s3I4Murrts5", {
    api_host: "https://eu.posthog.com",
  });

  Sentry.init({
    dsn: "https://226ef14de0882d7ec7a35cbf11be6b84@o4506905366626304.ingest.us.sentry.io/4506905399525376",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      {/* Google Analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-F0ZF43N096"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-F0ZF43N096');
        `}
      </script>
      {/* Clarity */}
      <script type="text/javascript">
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "lxe8q94wwt");
        `}
      </script>
    </Helmet>
    <App />
    <Toaster />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

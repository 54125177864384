import { requestWithAuth } from "@/utils/authUtils";

const PhonemeInstructorService = {
  async getPhonemeInstructions(referencePhoneme, userPronouncedPhoneme, language, nativeLanguage) {
    try {
      const url = "/phoneme-instructions";
      const method = "POST";
      const body = {
        reference_phoneme: referencePhoneme,
        user_pronounced_phoneme: userPronouncedPhoneme,
        language,
        native_language: nativeLanguage,
      };
      const headers = {
        "Content-Type": "application/json",
      };

      return await requestWithAuth(url, method, body, headers);
    } catch (error) {
      console.error("Error in getting phoneme instructions:", error);
      throw error;
    }
  },
};

export default PhonemeInstructorService;
// HomePage.js
import React from "react";
import LearningSession from "@/components/LearningSession/LearningSession";

const Home = () => {
  return (
    <div>
      <LearningSession />
    </div>
  );
};

export default Home;

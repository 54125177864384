// YouTubeImportDialog.js
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTitle, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import CollectionService from "@/services/CollectionService";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import posthog from "posthog-js";
import languageMap from "@/constants/languageMap";
import { ReloadIcon } from "@radix-ui/react-icons";
import useStore from "@/store";
import languageNames from "@/constants/languageNames";

import "/node_modules/flag-icons/css/flag-icons.min.css";

const YouTubeImportDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { setCurrentCollectionId } = useStore().user;
  const { setCurrentCollectionById } = useStore().collections;
  const [youTubeUrl, setYouTubeUrl] = useState("");
  const [numSubtitlesToMerge, setNumSubtitlesToMerge] = useState(1);
  const [title, setTitle] = useState("Untitled");
  const [language, setLanguage] = useState("en-us");
  const [isCreating, setIsCreating] = useState(false);

  function mapLanguageToCountry(language) {
    console.log("Language:", language);
    // Default mapping for 'en' to 'gb'
    if (language === "en") {
      return "gb";
    }

    // Specific mappings for variations of English
    switch (language) {
      case "ja":
        return "jp";
      case "hi":
        return "in";
      case "en-us":
        return "us";
      case "en-gb":
      case "en-gb":
        return "gb";
      case "zh":
        return "cn";
      case "ar":
        return "sa";
      case "ko":
        return "kr";
      default:
        // If no specific mapping is found, return the first part of the language code
        // This assumes language codes are in the format of 'language-country'
        return language.split("-")[0];
    }
  }

  const handleCreateCollection = async () => {
    try {
      setIsCreating(true);
      const collectionDetails = {
        title,
        language,
        youtubeUrl: youTubeUrl,
        type: "youtube",
        contents: [],
      };

      const createdCollection =
        await CollectionService.createCollectionWithContents(
          collectionDetails.title,
          collectionDetails.language,
          collectionDetails.contents,
          collectionDetails.type,
          collectionDetails.youtubeUrl,
          numSubtitlesToMerge
        );

      toast("Success", {
        description: "The collection was created successfully!",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
      posthog.capture("collection_created");
      await setCurrentCollectionById(createdCollection.id);
      await setCurrentCollectionId(createdCollection.id);
      navigate("/");
    } catch (error) {
      console.error("Failed to create collection:", error);
      toast("Error", {
        description: `Failed to create collection: ${error.response.data.error}`,
        action: {
          label: "Close",
          onClick: () => console.log("Error Close"),
        },
      });
    } finally {
      setIsCreating(false);
      setYouTubeUrl("");
      setNumSubtitlesToMerge(1);
      setTitle("Untitled");
      setLanguage("en");
      onClose();
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>Import From YouTube</DialogTitle>
        <div className="mb-4">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
            className="w-full"
          />
        </div>

        <div className="mb-4">
          <Label htmlFor="youtube-url">YouTube URL</Label>
          <Input
            id="youtube-url"
            type="text"
            value={youTubeUrl}
            onChange={(e) => setYouTubeUrl(e.target.value)}
            placeholder="Enter YouTube video URL"
            className="w-full"
          />
        </div>
        <div className="mb-4">
          <Label htmlFor="language">Language</Label>
          <div className="mb-4 flex">
            <Select
              className="w-full"
              defaultValue={language}
              onValueChange={setLanguage}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en-us">English - US</SelectItem>
                <SelectItem value="en-gb">English - UK</SelectItem>
                <SelectItem value="en-gb-x-rp">
                  English - Received Pronunciation
                </SelectItem>

                <SelectItem value="fr">French</SelectItem>
                <SelectItem value="fr-be">French - Belgium</SelectItem>
                <SelectItem value="fr-ch">French - Switzerland</SelectItem>

                <SelectItem value="de">German</SelectItem>
                {/* <SelectItem value="ja">Japanese</SelectItem> */}
                <SelectItem value="hi">Hindi</SelectItem>

                <SelectItem value="es">Spanish</SelectItem>
                <SelectItem value="es-419">Spanish - Latin American</SelectItem>

                {/* <SelectItem value="zh">Chinese</SelectItem> */}
                <SelectItem value="it">Italian</SelectItem>
                <SelectItem value="ru">Russian</SelectItem>

                <SelectItem value="pt">Portuguese</SelectItem>
                <SelectItem value="pt-br">Portuguese - Brazil</SelectItem>

                <SelectItem value="ar">Arabic</SelectItem>
                <SelectItem value="ko">Korean</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="mb-4">
          <Label htmlFor="num-subtitles">Number of Subtitles to Merge</Label>
          <Input
            id="num-subtitles"
            type="number"
            value={numSubtitlesToMerge}
            onChange={(e) => setNumSubtitlesToMerge(parseInt(e.target.value))}
            min={1}
            className="w-full"
          />
        </div>

        <div className="flex justify-center">
          <Button
            className="w-32 dark:bg-green-500 bg-green-500"
            onClick={handleCreateCollection}
            disabled={isCreating}
          >
            {isCreating ? (
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              "Create"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default YouTubeImportDialog;

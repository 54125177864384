import create from "zustand";
import ReadingPositionService from "@/services/ReadingPositionService";

const useLearningSessionConfigStore = create((set) => ({
  readingPosition: {
    collection_id: null,
    content_id: null,
    id: null,
    is_currently_active: false,
    last_updated: null,
    position: 0,
    user_id: null,
    wordPracticeDialogOpened: false,
    disableShortcuts: false,
  }, // Placeholder for the reading position object

  setDisableShortcuts: (disableShortcuts) => set({ disableShortcuts: disableShortcuts }),

  pauseVideo: false,
  triggerPauseVideo: () => set((state) => ({ pauseVideo: !state.pauseVideo })),

  continueVideo: false,
  triggerContinueVideo: () => set((state) => ({ continueVideo: !state.continueVideo })),

  setWordPracticeDialogOpened: (isOpen) =>
    set({
      wordPracticeDialogOpened: isOpen,
    }),
  setReadingPosition: (newReadingPosition) =>
    set({ readingPosition: newReadingPosition }),
  fetchActiveReadingPosition: async (collectionId) => {
    try {
      const readingPosition =
        await ReadingPositionService.getActiveReadingPosition(collectionId);
      set({ readingPosition });
      return readingPosition;
    } catch (error) {
      console.error("Error in fetching active reading position:", error);
    }
  },
  upsertReadingPosition: async (
    collectionId,
    contentId,
    position,
    isCurrentlyActive
  ) => {
    try {
      // Upsert reading position with the service
      const updatedReadingPosition =
        await ReadingPositionService.upsertReadingPosition(
          collectionId,
          contentId,
          position,
          isCurrentlyActive
        );
      // Update local state with the response
      set({ readingPosition: updatedReadingPosition });

      console.log(
        "Reading position updated successfully",
        updatedReadingPosition
      );
    } catch (error) {
      console.error("Failed to upsert reading position:", error);
    }
  },
  updatePositionInState: (newPosition) => {
    set((state) => ({
      readingPosition: {
        ...state.readingPosition,
        position: newPosition,
      },
    }));
  },
}));

export default useLearningSessionConfigStore;
